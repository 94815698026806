import React from 'react';

import { Routes, Route } from 'react-router-dom';
import MailToHeader from './MailToHeader';

import EmploymentTemplate from './EmploymentTemplate';

class MailToBaseLayout extends React.Component {

     render = () => {
          let path = this.props.match.path;
          console.log("path", path)
          return (
               <React.Fragment>
                    <MailToHeader/>
                    <div className="clr20"></div>
                    <Routes>
                         <Route path={`${path}/employment`} element={<EmploymentTemplate/>}/>
                    </Routes>
                    
               </React.Fragment>
          );
     }
}

export default MailToBaseLayout;
