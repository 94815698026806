import React, { Component } from 'react';
import { BTFieldValError } from './BTFieldValError';
import CustomErrorMessage from 'globals/CustomErrorMessage';

export class BTSelectField extends Component {

     constructor(props){
          super(props);
          this.state = {
               formData:{
                    [this.props.id]: '',
               },
               errors:{
                    [this.props.id]: '',
               },
               touched: {
                    [this.props.id]: false,
               }
          }
     }

     // Handle change
     handleChange = (e) => {
          const { name, value } = e.target;
          
          this.setState({errors: {[this.props.id]:value===""?`${this.props.id} is required`:''},
               touched:{[this.props.id]:true},formData:{[this.props.id]:value}});
          this.props.onChange(e);
     };



     render = () => {
          let isFieldEmpty = !!!this.props.value;
          const { selectClassName } = this.props;
          return (

               <div className={`form-group ${selectClassName ? selectClassName : ''}`}>
                    <label className="body2">{this.props.label}
                         {
                              !this.props.required ? <div className="optional">&nbsp;( optional )</div> : null
                         }
                    </label>
                    {/* {
                         this.props.required?<div className="error right">*</div>:''
                    } */}
                    <select id={this.props.id} className={`form-control ${isFieldEmpty ? 'input-empty' : ''}`}
                         name={this.props.id}
                         onBlur={this.props.onBlur}
                         onChange={this.handleChange}//{this.handleChange}
                         value={this.props.value}
                         readOnly={this.props.readonly}
                         disabled={this.props.isDisabled}
                    >
                         {this.props.options}
                    </select>
                    <CustomErrorMessage name={this.props.id} errors={this.state.errors} touched={this.state.touched} CustomComponent={BTFieldValError} />
               </div>


          );
     }
}