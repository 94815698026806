import { Formik } from 'formik';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { restbaseurl } from '../../../../globals/constants';
import { BTInputField } from '../../../../globals/uicomps/BTInputField';
import { LoadingSpinner } from '../../../../utils/LoadingSpinner';
import { RestDataSource } from '../../../../utils/RestDataSource';

let timeinterval;
class EmploymentFormContainer extends Component {
     constructor(props) {
          super(props);
          this.state = {
               loading: false,
               panNumber: '',
               password: '',
               captchaCode: '',
               requestId: '',
               jsessionId: '',
               verificationDone: false,
               otpValid: false,
               otpErrorMsg: '',
               errorMsg: '',
               captchaImg: '',
               otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '',
               otpStatus: true,
               count: 0,
               outputMsg: '',
               verifiedMessage: '',
               minutes: 0,
               seconds: 0,
               isURLValid: false,
               isFormSubmitted: false,
          }
          this.applicantId = '';
          this.hiddenMobileNumber = '';
          this.hiddenEmail = '';
     }

     componentDidMount = () => {
          this.timer()
          this.init()
          this.generateOtp()
     }

     init = () => {
          this.empId = (this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1));
          if (!(this.empId)) {
               this.setState({
                    isURLValid: false
               })
          } else {
               this.getNumber = new RestDataSource(`${restbaseurl}/user/getUserDetails`);
               this.getNumber.GetData(
                    data => {
                         if (data.status === true) {
                              this.hiddenMobileNumber = data.data.mobileNumber;
                              this.hiddenEmail = data.data.email;
                         }
                    },
                    { 'id': this.empId },
                    "POST"
               );
               this.setState({
                    isURLValid: true
               })
          }
     }

     generateOtp = () => {
          this.dataSource = new RestDataSource(`${restbaseurl}/applicantCase/generateOtp`);
          this.dataSource.GetData(
               data => {
                    if (data.status === true) {
                         console.log("Inside genrate otp success ****")
                    }
               },
               { 'id': this.empId },
               "POST"
          );
     }

     loadForm = () => {
          this.dataSource = new RestDataSource(`${restbaseurl}/applicantCase/empVerificationOnline`);
          this.setState({ loading: true });
          this.dataSource.GetData(
               data => {
                    if (data.status === true) {
                         this.setState({
                              loading: false,
                              captchaImg: data.imageLink,
                              jsessionId: data.jsessionId,
                              requestId: data.requestId
                         })
                    }
               },
               {},
               "POST"
          );
     }

     submitForm = () => {
          this.dataSource = new RestDataSource(`${restbaseurl}/applicantCase/startEmpOnlineVerification`);
          this.setState({ loading: true, isFormSubmitted: true });
          this.dataSource.GetData(
               data => {
                    this.setState({ loading: false, isFormSubmitted: false })
                    if (data.errorStatus === true) {
                         console.log("dtats ins submit form", data)
                         this.setState({ errorMsg: data.errorMsg })
                    } else {
                         this.setState({ verificationDone: true, outputMsg: data.output })
                    }
               },
               {
                    'jsessionId': this.state.jsessionId, 'requestId': this.state.requestId, 'panNumber': this.state.panNumber, 'password': this.state.password, 'captchaCode': this.state.captchaCode, 'id': this.empId
               },
               "POST"
          );
     }

     handleInputChange = (e) => {
          let name = e.target.getAttribute('name')
          let value = e.target.value
          this.setState({
               [name]: value
          })
     }

     handleChange = (e) => {

          const re = /^\d$/;
          // if value is not blank, then test the regex      
          if (e.target.value === '' || re.test(e.target.value)) {
               //this.setState({value: e.target.value})
               this.setState({ [e.target.id]: e.target.value });
               if (e.target.value.length >= e.target.getAttribute("maxlength") && e.target.id !== "otp6") {
                    e.target.nextElementSibling.focus();
               }
          }

     }

     time_remaining = (endtime) => {
          let t = Date.parse(endtime) - Date.parse(new Date());
          let seconds = Math.floor((t / 1000) % 60);
          let minutes = Math.floor((t / 1000 / 60) % 60);
          return { 'total': t, 'minutes': minutes, 'seconds': seconds };
     }

     timer = () => {

          let current_time = Date.parse(new Date());
          let deadline = new Date(current_time + 300000);
          this.update_clock = () => {
               let t = this.time_remaining(deadline);
               this.setState({ minutes: t.minutes, seconds: t.seconds })
               if (t.total <= 0) {
                    clearInterval(timeinterval);
                    this.setState({ verifiedMessage: "Link has expired. Try again" })
               }
          }

          this.update_clock();
          timeinterval = setInterval(this.update_clock, 1000);
     }

     verifyOTP = () => {
          let otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
          otp = String(otp);
          this.dataSource = new RestDataSource(`${restbaseurl}/applicantCase/checkOtp`);
          this.dataSource.GetData(
               data => {
                    if (data.errorStatus === false) {
                         this.loadForm();
                         this.setState({
                              otpValid: true
                         })
                    } else {
                         this.setState({
                              otpValid: false,
                              otpErrorMsg: 'Otp that you have entered seems wrong !! Please try again'
                         })
                    }
               },
               {
                    'otp': otp, 'id': this.empId
               },
               "POST"
          );
     }

     resendOTP = () => {
          this.generateOtp();
          clearInterval(timeinterval)
          this.timer();
     }

     isOTPFilled = () => {
          return this.state.otp1.length > 0 && this.state.otp2.length > 0 && this.state.otp3.length > 0 && this.state.otp4.length > 0 && this.state.otp5.length > 0 && this.state.otp6.length > 0
     }

     closeBrowser = () => {
          window.open("about:blank", "_self");
          window.close();
     }

     render = () => {

          const { panNumber, password, captchaCode, captchaImg, jsessionId, requestId, otpValid, loading, errorMsg, otpErrorMsg, verificationDone, isURLValid, isFormSubmitted } = this.state
          const { t } = this.props
          return (
               loading === true ?
                    <><LoadingSpinner /> {isFormSubmitted ? <h6 className='loading_spinner'>Kindly wait for some time until the process is complete. Do not refresh the page.</h6> : ''}</> :
                    isURLValid ? <>
                         <div className="container-fluid case-settings">
                              <div className="container container-case case-settings white-bg panform" style={{ padding: '50px' }}>
                                   {otpValid
                                        ?
                                        verificationDone
                                             ?
                                             <>
                                                  <div className="imageOtp">
                                                       <img src={"/images/icons/thankuresponding.jpg"} alt="otp_image" />
                                                  </div>
                                                  <div className="text-center">
                                                       <h5>Thank you for responding and We appreciate your assistance.</h5>
                                                       <div className='clr20'></div>
                                                       <span className="btn btn_primary" onClick={this.closeBrowser}>Finish</span>
                                                  </div>
                                             </>
                                             :
                                             <>
                                                  <div className=" text-center  heading">
                                                       Enter your details
                                                  </div>
                                                  <div className="row">
                                                       <div className="col-12 row col-sm-12">
                                                            <div className="col-6 col-sm-6" style={{ marginTop: '35px' }}>
                                                                 <div className="col-12 col-sm-12">
                                                                      <BTInputField id="panNumber" label={t("pan_number")} type="text" required={true} className="displayFlex"
                                                                           value={panNumber} handleChange={this.handleInputChange} />
                                                                 </div>
                                                                 <div className="col-12 col-sm-12">
                                                                      <BTInputField label={t('password')} type="password" id="password" value={password} handleChange={this.handleInputChange} required={true} className="displayFlex" />
                                                                 </div>
                                                                 <div className="col-12 col-sm-12">
                                                                      <div style={{ display: 'flex', marginBottom: '55px' }}>
                                                                           <label className='body2' style={{ width: '125px' }}>Captcha</label>
                                                                           <img src={captchaImg} alt="captcha_image" style={{ marginTop: '-10px' }} />
                                                                      </div>
                                                                 </div>
                                                                 <input type="hidden" id="jsessionId" value={jsessionId} />
                                                                 <input type="hidden" id="requestId" value={requestId} />
                                                                 <div className="col-12 col-sm-12">
                                                                      <BTInputField label={t('Enter Captcha')} type="text" id="captchaCode" value={captchaCode} handleChange={this.handleInputChange} required={true} className="displayFlex" />
                                                                 </div>
                                                                 {errorMsg.length > 0 ?
                                                                      <div className="showerrormsg">
                                                                           <span>{errorMsg}</span>
                                                                      </div> : ""
                                                                 }
                                                                 <div className="col-12 col-sm-12 text-center">
                                                                      <input type="submit" value="Submit" className={"btn btn_primary ml10 "} onClick={this.submitForm} />
                                                                 </div>
                                                            </div>
                                                            <div className="vl col-1 col-sm-1"></div>
                                                            <div className="col-5 col-sm-5 tds-verification">
                                                                 <ul>
                                                                      <li>Enter your ITR password</li>
                                                                      <li>Your password is encrypted and will not be stored</li>
                                                                      <li>If you are not a registered user, please <a href="https://www1.incometaxindiaefiling.gov.in/e-FilingGS/Registration/RegistrationHome.html?lang=eng" rel="noopener noreferrer" target='_blank'>click here</a></li>
                                                                      <li>Highly Secure</li>
                                                                      <li>Data is used only for verification purpose only</li>
                                                                 </ul>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </>
                                        :
                                        <>
                                             <div className="imageOtp">
                                                  <img src={"/images/icons/unnamed.png"} alt="otp_image" />
                                             </div>
                                             <div className=" text-center  heading">
                                                  Enter OTP
                                             </div>
                                             <Formik onSubmit={this.verifyOTP} initialValues={this.initialValues}>
                                                  {
                                                       ({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                                                            <form onSubmit={handleSubmit} noValidate >
                                                                 <div className="form-group employment-otp">
                                                                      <label className="body2 line-height-2">OTP has been sent to {this.hiddenEmail} and {this.hiddenMobileNumber} </label>
                                                                      <div className="otp-inputs">
                                                                           <input type="text" id="otp1" className="form-control"
                                                                                value={this.state.otp1}
                                                                                autoFocus={true} maxLength={1} onChange={this.handleChange}
                                                                           />
                                                                           <input type="text" id="otp2" className="form-control"
                                                                                value={this.state.otp2} onChange={this.handleChange}
                                                                                maxLength={1} />
                                                                           <input type="text" id="otp3" className="form-control"
                                                                                value={this.state.otp3} onChange={this.handleChange}
                                                                                maxLength={1} />
                                                                           <input type="text" id="otp4" className="form-control"
                                                                                value={this.state.otp4} onChange={this.handleChange}
                                                                                maxLength={1} />
                                                                           <input type="text" id="otp5" className="form-control"
                                                                                value={this.state.otp5} onChange={this.handleChange}
                                                                                maxLength={1} />
                                                                           <input type="text" id="otp6" className="form-control"
                                                                                maxLength={1} value={this.state.otp6} onChange={this.handleChange}
                                                                           />

                                                                      </div>
                                                                      <div>
                                                                           <label className="body2 left mt10">OTP will expire in {this.state.minutes}m {this.state.seconds}s</label>
                                                                      </div>
                                                                      <label className="forgetlink body2 right mt10 pointer" onClick={this.resendOTP}>
                                                                           Resend OTP
                                                                      </label>
                                                                 </div>
                                                                 <div className="clr20"></div>
                                                                 {otpErrorMsg.length > 0 ?
                                                                      <div className="showerrormsg">
                                                                           <span>{otpErrorMsg}</span>
                                                                      </div> : ""
                                                                 }
                                                                 <div style={{ width: '200px', marginLeft: '385px' }}>
                                                                      <input type="submit" className="btn btn_primary block" value="VERIFY & PROCEED" disabled={!this.isOTPFilled()}></input>
                                                                 </div>
                                                            </form>
                                                       )
                                                  }
                                             </Formik>
                                        </>
                                   }
                              </div>
                         </div>
                    </> :
                         <div className='text-center'>
                              <h6 className='mt10'>Invalid URL.Kindly Check the URL !!!</h6>
                         </div>
          )
     }
}
export default withTranslation('translations')(EmploymentFormContainer)