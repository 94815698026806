import React, { Component } from 'react';
import PackInfoModal from '../clientAdmin/clientinit/PackInfoModal';
import { restbaseurl } from '../globals/constants';
import ConfirmationModal from '../globals/uicomps/ConfirmationModal';
import { RestDataSource } from '../utils/RestDataSource';
import BTSnackbar from '../globals/uicomps/BTSnackbar';
import UserInfo from '../models/UserInfo';
import { getValue } from '../globals/utils';
class TopPanel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               loading: false,
               content: "",
               packDetails: [],
               packName: '',
               isReportShared: this.props.isReportShared,
               isCommentEditable: false,
               comments: this.props.comments,
               previousComment: this.props.comments,
               addNewComment: false,
               showPackInfoModal: false,
               activeTab: 1,
               showConfirmationModal: false,
               confirmationSubheading: '',
               confirmationHeading: ' ',
               emails: this.props.emailForMailerTemplate,
               isErrorExist: false,
               errorMsg: '',
               triggerMailConfirmationHeading: '',
               triggerMailCconfirmationSubheading: '',
               showTriggerMailConfirmationModal: false,
               snackbar: false,
               snackmsg: '',
               snackStatus: '',
               ccEmails: this.props.caseObj ? this.props.caseObj.ccEmailForMailerTemplate : [],
               showInterimReportModal: false,
               isInterimReport: this.props.isInterimReport,
               isFirstInterimReportShared: this.props.isFirstInterimReportShared,
               showFirstInterimReportModal: false,
               payloadData: null
          }
          this.userid = UserInfo.getUserid()
          getValue().then((payloadData) => {
               this.setState({ payloadData: payloadData });
          });

     }

     updateNewDetails = (e) => {
          let name = e.target.getAttribute("id");
          let value = e.target.value;
          this.setState({
               [name]: value
          });
     }

     isEmailValid = (email) => {
          // eslint-disable-next-line no-useless-escape
          let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (re.test(String(email).toLowerCase()));
     }

     handleSave = () => {
          let isErrorExist = false;
          let errorMsg = '';
          let { emails, ccEmails } = this.state;
          let ccEmailList = ccEmails.split(',');
          let emailList = emails.split(',');
          let { isMailerTemplateAvailable } = this.props;
          if (isMailerTemplateAvailable) {
               emailList.forEach(data => {
                    let isEmailValid = this.isEmailValid(data)
                    if (!isEmailValid) {
                         isErrorExist = true;
                         errorMsg = 'Provide only emails and comma'
                    }
               })
               ccEmailList.forEach(data => {
                    let isCcEmailValid = this.isEmailValid(data)
                    if (!isCcEmailValid) {
                         isErrorExist = true;
                         errorMsg = 'Provide only emails and comma'
                    }
               })
          }
          if (isErrorExist && isMailerTemplateAvailable) {
               this.setState({
                    isErrorExist,
                    errorMsg
               })
          } else {
               const { emails, ccEmails } = this.state;
               this.triggerMail = new RestDataSource(`${restbaseurl}/applicantCase/triggerMail`);
               this.triggerMail.GetData(
                    respData => {
                         console.log(respData, "respData")
                    },
                    {
                         applicantCaseId: this.props.caseId,
                         emails: emails,
                         ccEmails: ccEmails,
                    },
                    "POST"
               );
               this.setState({
                    confirmationHeading: ' ',
                    confirmationSubheading: '',
                    showConfirmationModal: false,
                    snackbar: true,
                    snackmsg: 'Mail Sent Successfully',
                    snackStatus: 'success',
               })
               this.props.reRender()
          }
     }
     reportReadyMailAvailable = () => {
          if (this.props.isMailerTemplateAvailable === true) {
               this.setState({
                    confirmationHeading: ' ',
                    confirmationSubheading: 'A mail will triggered to the client regarding this change . Do you wish to continue ?',
                    showConfirmationModal: true,
               })
          } else {
               this.setState({
                    confirmationHeading: 'Are you sure ? ',
                    confirmationSubheading: '',
                    showConfirmationModal: true,
               })
          }
     }

     hideConfirmationModal = () => {
          this.setState({ showConfirmationModal: false })
     }

     showTriggerMailModal = () => {
          this.reportReadyMailAvailable()
     }

     sendRequest = () => {
          this.caseDetails = new RestDataSource(`${restbaseurl}/pack/showPackDetails`);
          this.setState({ loading: true }, () => {
               this.caseDetails.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   packDetails: data.data.packList,
                                   packName: data.data.packName,
                                   showPackInfoModal: true
                              })
                         }
                    },
                    {
                         userId: this.userid,
                         clientId: this.state.payloadData.clientId,
                         packId: this.props.packId,

                    },
                    "POST"
               );
          });

     }

     closePackInfoModal = () => {
          this.setState({
               showPackInfoModal: false
          })
     }

     handleEmailChange = (e) => {
          let name = e.target.name;
          let value = e.target.value;
          this.setState({
               [name]: value,
          })
     }

     handleReportShare = () => {
          if (this.state.isReportShared) {
               this.setState({
                    showReportShareConfirmation: true,
                    reportShareModalHeading: 'Are you sure ? ',
                    reportShareModalSubheading: '',
               })
          }
          else {
               this.setState({
                    showReportShareConfirmation: true,
                    reportShareModalHeading: 'Are you sure ? ',
                    reportShareModalSubheading: 'Do you wish to mark this case completed',
               })
          }

     }

     handleSaveReportShare = () => {
          let isReportShared = !this.state.isReportShared
          this.setState({
               isReportShared: isReportShared
          })
          this.isReportShared = new RestDataSource(`${restbaseurl}/applicantCase/updateReportSharedStatus`);
          this.isReportShared.GetData(
               respData => {
                    console.log(respData, "respData")
               },
               {
                    applicantCaseId: this.props.caseId,
                    isReportShared: isReportShared,
                    clientCode: this.state.payloadData.clientCode
               },
               "POST"
          );
          this.setState({
               reportShareModalSubheading: ' ',
               reportShareModalHeading: '',
               showReportShareConfirmation: false,
               snackbar: true,
               snackmsg: 'Saved',
               snackStatus: 'success',
          })
          this.props.reRender()
     }

     hideSnackbar = () => {
          this.setState({ snackbar: false, snackmsg: '', snackStatus: '' })
     }

     handleInterimReportCheckbox = () => {
          this.setState({ showInterimReportModal: true });
     }

     handleFirstInterimReportCheckbox = () => {
          this.setState({ showFirstInterimReportModal: true })
     }

     cbInterimReportConfirmSuccess = () => {
          const newInterimStatus = !this.state.isInterimReport;
          this.updateInterimReportReq = new RestDataSource(`${restbaseurl}/applicantCase/updateInterimReportStatus`);
          this.updateInterimReportReq.GetData(
               respData => {
                    console.log(respData, "respData")
                    if (respData.status) {
                         this.setState({
                              isInterimReport: newInterimStatus,
                              showInterimReportModal: false,
                              snackbar: true,
                              snackmsg: 'Saved',
                              snackStatus: 'success',
                         });
                         this.props.cbInterimReportChange();
                    } else {
                         this.setState({
                              showInterimReportModal: false,
                              snackbar: true,
                              snackmsg: 'Saved',
                              snackStatus: 'danger',
                         });
                    }

               },
               {
                    id: this.props.caseId,
                    isInterimReport: newInterimStatus
               },
               "POST"
          );
     }

     cbFirstInterimReportConfirmSuccess = () => {
          const newFirstInterimReportStatus = !this.state.isFirstInterimReportShared;
          this.updateInterimReportReq = new RestDataSource(`${restbaseurl}/applicantCase/updateFirstIRShared`);
          this.updateInterimReportReq.GetData(
               respData => {
                    console.log(respData, "respData")
                    if (respData.status) {
                         this.setState({
                              isFirstInterimReportShared: newFirstInterimReportStatus,
                              showFirstInterimReportModal: false,
                              snackbar: true,
                              snackmsg: 'Saved',
                              snackStatus: 'success',
                         });
                         this.props.cbFirstInterimReportShared();
                    } else {
                         this.setState({
                              showInterimReportModal: false,
                              snackbar: true,
                              snackmsg: 'Saved',
                              snackStatus: 'danger',
                         });
                    }

               },
               {
                    id: this.props.caseId,
                    isFirstInterimReport: newFirstInterimReportStatus
               },
               "POST"
          );
     }

     render = () => {
          let payloadData;
          let { errorMsg,
               isErrorExist,
               showPackInfoModal,
               packName,
               packDetails,
               snackbar,
               snackmsg,
               snackStatus,
               ccEmails,
               isInterimReport,
               isFirstInterimReportShared,
          } = this.state;
          let {
               isMailerTemplateAvailable,
               interimReportDate,
               caseType,
               firstInterimReportSharedDate
          } = this.props;
          let accessReportEdit = false;
          let accessReportShare = false;
          let accessInterimReportCheckbox = false;
          getValue().then((pData) => {
               payloadData = pData;
               if (payloadData && payloadData['rolePriority'] <= payloadData['roleMap']['chp'] || payloadData['roleCode'] === 'reportviewer' || payloadData['roleCode'] === 'de' || payloadData['roleCode'] === 'qc') {
                    accessReportEdit = true
               }

               if (payloadData && (payloadData['rolePriority'] <= payloadData['roleMap']['chp'] || payloadData['roleCode'] === 'reportviewer')) {
                    accessReportShare = true;
                    accessInterimReportCheckbox = true;
               }
          })
          return (payloadData &&
               <>
                    {
                         snackbar ? <BTSnackbar msg={snackmsg} time="2000" status={snackStatus} onHide={this.hideSnackbar} /> : ''
                    }
                    <div className="sidepop_header_expand">
                         {/* <Accordion> */}
                         <div id="case-tbl-user-accordion" className="case_tbl_user_accordion">
                              <div className="card">
                                   <div className="pack-accordion-head" >
                                        <div className="left">
                                             <div className="sub-heading1">{this.props.firstname ? this.props.firstname : 'User'}
                                                  {accessReportEdit ? <a href={`/reportPreview?id=${this.props.caseId}`} target="_blank" rel="noopener noreferrer" className='pack-info-icon-wrap report_preview_wrap'>
                                                       <img height="20" width="20" style={{ marginLeft: "10px" }} src={"/images/icons/preview-icon.png"} alt="preview" />
                                                       <div className="info-pack-details">
                                                            <div className="sub-heading2">Report Preview</div>
                                                       </div>
                                                  </a> : null}
                                                  <span className="pack-info-icon-wrap  pointer pack_info_dashboard" onClick={this.sendRequest}>
                                                       <img src={"/images/icons/icon-info.svg"} height="20" alt="pack-info" />
                                                       <div className="info-pack-details">
                                                            <div className="sub-heading2">Pack Info</div>
                                                       </div>
                                                  </span>
                                                  {isMailerTemplateAvailable ? <span className="pack-info-icon-wrap  pointer pack_info_dashboard" onClick={this.showTriggerMailModal}>
                                                       <img src={"/images/icons/icon-mail.svg"} height="20" alt="pack-info" />
                                                       <div className="info-pack-details">
                                                            <div className="sub-heading2">Trigger Mail</div>
                                                       </div>
                                                  </span> : null}
                                                  {showPackInfoModal ?
                                                       <React.Fragment>
                                                            <PackInfoModal show={showPackInfoModal} packName={packName} packDetails={packDetails} onHide={this.closePackInfoModal} />
                                                       </React.Fragment> : null

                                                  }
                                             </div>
                                             <div className="clr5"></div>
                                             <div><b className="smallfont">{this.props.caseRefNumber}</b></div>
                                             <div className="body2 mt10 title-hover default cursor-default">{this.props.dateCreated ? this.props.dateCreated : 'Created Date'}
                                                  <div className="icon-details pad10" style={{ width: '150px' }}>
                                                       <div className="">System Created Date</div>
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="right">
                                             {accessReportShare ? <div className="form-group search-filter  checkclass inline-tag mt10 reportshare">
                                                  <label className="container_Checkbox roundedcheckbox mb0">{String(payloadData['clientCode']).includes("HCL") ? 'Report Shared' : 'Final Report'}
                                                       <input type="checkbox" id="checkbox" onChange={this.handleReportShare} checked={!!this.state.isReportShared} />
                                                       <span className="checkmark"></span>
                                                  </label>
                                             </div> : null}
                                             {accessInterimReportCheckbox && !String(payloadData['clientCode']).includes("HCL") ?
                                                  <React.Fragment>
                                                       <div className="interim-report-checkbox">
                                                            <div className="form-group mb0">
                                                                 <label className="container_Checkbox roundedcheckbox mb0">
                                                                      <span>Interim Report</span>
                                                                      <input type="checkbox" id="checkbox" onChange={this.handleInterimReportCheckbox} checked={isInterimReport} />
                                                                      <span className="checkmark"></span>
                                                                 </label>
                                                            </div>
                                                            {isInterimReport ? <div className="icon-details pad10 hover-show" style={{ width: '150px' }}>
                                                                 <div className="">{interimReportDate}</div>
                                                            </div> : null}
                                                       </div>
                                                  </React.Fragment>
                                                  : null}
                                             {accessInterimReportCheckbox && String(payloadData['clientCode']).includes("HCL") && caseType === 'preJoining' ?
                                                  <React.Fragment>
                                                       <div className="first-interim-report-checkbox">
                                                            <div className="form-group mb0">
                                                                 <label className="container_Checkbox roundedcheckbox mb0">
                                                                      <span>1st IR Shared</span>
                                                                      <input type="checkbox" id="checkbox" onChange={this.handleFirstInterimReportCheckbox} checked={isFirstInterimReportShared} />
                                                                      <span className="checkmark"></span>
                                                                 </label>
                                                            </div>
                                                            {isFirstInterimReportShared ? <div className="icon-details pad10 hover-show" style={{ width: '150px' }}>
                                                                 <div className="">{firstInterimReportSharedDate}</div>
                                                            </div> : null}
                                                       </div>
                                                  </React.Fragment>
                                                  : null}
                                        </div>
                                        {/* <b className="smallfont right">{this.props.caseRefNumber}</b> */}
                                        {/* <Accordion.Toggle className="right" as="span" variant="link" eventKey="0">
                                             <img className="right pointer" src={"images/icons/icon-select-arrow.svg"} height={24} width={24} alt="icon-arrow" />
                                        </Accordion.Toggle> */}
                                   </div>
                                   {/* <Accordion.Collapse eventKey="0"> */}
                                   <div className={"collapse navbar-collapse show"} >

                                   </div>
                                   {/* </Accordion.Collapse> */}
                              </div>
                              <div className="clr"></div>
                         </div>
                         <div className="clr"></div>
                         {/* </Accordion> */}
                         <ConfirmationModal
                              show={this.state.showConfirmationModal}
                              heading={this.state.confirmationHeading}
                              subheading={this.state.confirmationSubheading}
                              successButtonText="Yes"
                              failureButtonText="No"
                              successCallback={() => { this.handleSave() }}
                              failureCallback={() => { this.setState({ showConfirmationModal: false }) }}
                              closeCallback={() => { this.setState({ showConfirmationModal: false }) }}
                              hideFooter={isMailerTemplateAvailable}
                         >
                              {isMailerTemplateAvailable ? <><div className="row">
                                   <div className="col-12 col-md-12">
                                        <div className="form-group mt10 mb10">
                                             <label>Client email</label>
                                             <div className='sub-heading4 mb10 inlinedisplay'>  (You can provide multiple email by comma separated)</div>
                                        </div>
                                   </div>
                                   <div className="col-12 col-md-12">
                                        <div className="form-group">
                                             <textarea type="email" className="form-control noresize h70" id='emails' name="emails" value={this.state.emails} onChange={this.handleEmailChange} />
                                        </div>
                                   </div>
                                   <div className="col-12 col-md-12">
                                        <div className="form-group mt10 mb10">
                                             <label>Cc</label>
                                             <div className='sub-heading4 mb10 inlinedisplay'>  (You can provide multiple email by comma separated)</div>
                                        </div>
                                   </div>
                                   <div className="col-12 col-md-12">
                                        <div className="form-group">
                                             <textarea type="email" id='ccEmails' className="form-control noresize h70" name="ccEmails" value={ccEmails} onChange={this.handleEmailChange} />

                                        </div>
                                   </div>
                                   {isErrorExist ? <label className="error right">{errorMsg}</label> : ''}
                                   <div className="col-12 text-center">
                                        <span className={`btn btn_primary  ml10 ${this.state.emails ? '' : 'btn_disabled'}`} onClick={this.handleSave}>Save</span>
                                        <span className={`btn btn_secondary  ml10 `} onClick={this.hideConfirmationModal}>Cancel</span>
                                   </div>
                              </div> </> : null}
                         </ConfirmationModal>

                         <ConfirmationModal
                              show={this.state.showReportShareConfirmation}
                              heading={this.state.reportShareModalHeading}
                              subheading={this.state.reportShareModalSubheading}
                              successButtonText="Yes"
                              failureButtonText="No"
                              successCallback={() => { this.handleSaveReportShare() }}
                              failureCallback={() => { this.setState({ showReportShareConfirmation: false }) }}
                              closeCallback={() => { this.setState({ showReportShareConfirmation: false }) }}
                         // hideFooter={isMailerTemplateAvailable}
                         >
                         </ConfirmationModal>

                         <ConfirmationModal
                              show={this.state.showInterimReportModal}
                              heading="Are you sure?"
                              subheading=""
                              successButtonText="Yes"
                              failureButtonText="No"
                              successCallback={() => { this.cbInterimReportConfirmSuccess() }}
                              failureCallback={() => { this.setState({ showInterimReportModal: false }) }}
                              closeCallback={() => { this.setState({ showInterimReportModal: false }) }}
                         >
                         </ConfirmationModal>

                         <ConfirmationModal
                              show={this.state.showFirstInterimReportModal}
                              heading="Are you sure?"
                              subheading=""
                              successButtonText="Yes"
                              failureButtonText="No"
                              successCallback={() => { this.cbFirstInterimReportConfirmSuccess() }}
                              failureCallback={() => { this.setState({ showFirstInterimReportModal: false }) }}
                              closeCallback={() => { this.setState({ showFirstInterimReportModal: false }) }}
                         >
                         </ConfirmationModal>
                    </div>
               </>

          );
     }
}

export default TopPanel;
