import React, { Component } from 'react';
import { ErrorMessage } from 'formik';
import { BTFieldValError } from './BTFieldValError';
import CustomErrorMessage from 'globals/CustomErrorMessage';

export class BTInputField extends Component {
     constructor(props) {
          super(props);
          this.state = {
               formData: {
                    [this.props.id]: '',
               },
               errors: {
                    [this.props.id]: '',
               },
               touched: {
                    [this.props.id]: false,
               }
          }
     }

     handleBlur = (e) => {
          const { name, value } = e.target;

          this.setState({
               errors: { [this.props.id]: value === "" ? `${this.props.id} is empty` : '' },
               touched: { [this.props.id]: true }, formData: { [this.props.id]: value }
          },()=>{
               this.props.handleBlur && this.props.handleBlur(e);
          });
          
     };

     render = () => {
          let isFieldEmpty = !!!this.props.value;
          const { className } = this.props;
          return (

               <div className={`form-group ${className ? className : ''}`}>
                    <label className="body2">{this.props.label}
                         {
                              !this.props.required ? <div className="optional">&nbsp;( optional )</div> : null
                         }
                    </label>
                    {/* {
                         this.props.required ? <div className="error right">*</div> : null
                    } */}
                    <input type={this.props.type === undefined ? 'text' : this.props.type}
                         min={this.props.min} max={this.props.max}
                         readOnly={this.props.readonly}
                         disabled={this.props.disabled}
                         name={this.props.id} id={this.props.id} className={`form-control ${isFieldEmpty ? 'input-empty' : ''}`}
                         onBlur={this.handleBlur}
                         placeholder={this.props.placeholder ? this.props.placeholder : 'Enter here'}
                         value={this.props.value} onChange={this.props.handleChange} />
                    <CustomErrorMessage name={this.props.id} errors={this.state.errors} touched={this.state.touched} CustomComponent={BTFieldValError} />
               </div>


          );
     }
}