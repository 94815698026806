import React, { Component } from 'react';
import { restbaseurl } from "../globals/constants";
import { RestDataSource } from "../utils/RestDataSource";
import { withTranslation } from 'react-i18next';
import { Navbar } from "react-bootstrap";
import * as ls from "local-storage";
import UserInfo from '../models/UserInfo';
import { getValue } from '../globals/utils';
import ChangePassword from '../clientAdmin/administration/ChangePassword';
import DialogModal from '../globals/uicomps/DialogModal';


class ReportViewerHeader extends Component {

     constructor(props) {
          super(props);
          this.state = {
               isMenuOpen: false,
               logo: "",
               userId: UserInfo.getUserid(),
               showChangePasswordModal: false,
               rolePriorityList:[]
          };
     }

     toggleMenu = () => {
          const { isMenuOpen } = this.state;
          this.setState({ isMenuOpen: !isMenuOpen });
     }

     onLogout = () => {
          ls.clear();
          document.location.href = '/';
     }

     componentDidMount = async () => {
          this.setState({rolePriorityList: await getValue("rolePriorityList")});
          this.dataSource = new RestDataSource(
               `${restbaseurl}/user/showUserProfile`,
          );
          this.dataSource.GetData(
               (data) => {
                    if (data.status === true) {
                         this.setState({
                              username: data.data.firstname,
                              email: data.data.email,
                              profileImage: data.data.profileImage,
                         });
                    }
               },
               { userId: UserInfo.getUserid() },
               'POST',
          );
     };

     showSwitchRole = () => {
          window.location.href = '/chooseRole';
     }

     handleChangePassword = () => {
          this.setState({
               showChangePasswordModal: true
          })
     }

     cbChangePassword = () => {
          this.setState({
               snackbar: true,
               snackmsg: 'Updated Successfully',
               showChangePasswordModal: false
          })
     }

     hideModal = () => {
          this.setState({
               showChangePasswordModal: false
          })
     }

     render = () => {
          const { t, showNavTabs, activeTab, cbChangeBaseTab, navList } = this.props;
          const {
               profileImage, isMenuOpen, username, email
          } = this.state;
          let showSwitchRole = false;
          if (this.state.rolePriorityList && this.state.rolePriorityList.length >= 2) {
               showSwitchRole = true;
          }
          let action = '/user/changePassword';
          return (
               <React.Fragment>
     
                    <Navbar className="box-shadow-card" fixed="top">
                         <Navbar.Brand href="/"><img src="/images/logo.svg" alt="metapath" className="companylogoclass" /></Navbar.Brand>
                         <Navbar.Toggle aria-controls="responsive-navbar-nav">
                              <div className="toggle-wrap">
                                   <span className="toggle-bar" />
                              </div>
                         </Navbar.Toggle>
                         <Navbar.Collapse id="responsive-navbar-nav">
                              {showNavTabs
                                        ? (
                                             <ul className="navbar-nav m-auto">
                                                  {
                                                       navList.map((tabObj) => {
                                                            let tabName = tabObj.name;
                                                            let route = tabObj.route;
                                                            let classList = 'nav-item pointer';
                                                            if (tabName === activeTab) {
                                                                 classList = classList.concat(' active');
                                                            }
                                                            return (
                                                                 <li className={classList} key={route}>
                                                                      <button className="nav-link btn-link" onClick={() => cbChangeBaseTab(route)} type="button">{tabName}</button>
                                                                 </li>
                                                            );
                                                       })
                                                  }
                                             </ul>
                                        )
                                        : ''}
                              <div className="right-navs rel mr-2">
                                   <div className="btn-group profile-out">
                                        {profileImage == null
                                             ? (
                                                  <button onClick={this.toggleMenu} type="button" className="img_button">
                                                       <img
                                                            height="20" width="20" src="/images/icons/icon-user.svg" data-toggle="dropdown"
                                                            className="rounded-circle pointer" alt="profileImage"
                                                       />
                                                  </button>
                                             ) : (
                                                  <button onClick={this.toggleMenu} type="button" className="img_button">
                                                       <img
                                                            height="20" width="20" src={profileImage}
                                                            data-toggle="dropdown" className="rounded-circle pointer" alt="profile"
                                                       />
                                                  </button>
                                             )
                                        }

                                        <div className={`dropdown-menu${isMenuOpen ? ' show' : ''}`} aria-labelledby="dropdownMenuButton">
                                             <div className="profile-drop">
                                                  <div className="media">
                                                       {profileImage == null
                                                            ? <img src="/images/icons/icon-user.svg" alt="user" /> : <img src={profileImage} alt="profileimage" />}
                                                       <div className="media-body">
                                                            <div className="sub-heading2">{username}</div>
                                                            <div className="body3">{email}</div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="drop-link">
                                                  {showSwitchRole ? <div style={{ cursor: "pointer" }} className="dropdown-item" onClick={this.showSwitchRole}>{t('Switch Role')}</div> : null }
                                                  <div style={{ cursor: "pointer" }} className="dropdown-item" onClick={this.handleChangePassword}>{t('Change Password')}</div>
                                                  <button type="button" onClick={this.onLogout} className="img_button dropdown-item">   <div style={{ cursor: 'pointer' }}>{t('Logout')}</div></button>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </Navbar.Collapse>
                    </Navbar>
                    <div className="modal-dialog ">
                         <DialogModal
                              show={this.state.showChangePasswordModal}
                              header="Change Password"
                              successButtonText="Save"
                              failureButtonText="Cancel"
                              onHide={this.hideModal}
                              className="reset-popup"
                              showFooter="false"
                         >
                              <ChangePassword
                                   cbChangePassword={this.cbChangePassword}
                                   onHide={this.hideModal}
                                   action={action}
                              />
                         </DialogModal>
                    </div>
               </React.Fragment>
          );
     }
}

export default withTranslation('translations')(ReportViewerHeader);
