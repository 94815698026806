import Axios from 'axios';
import * as ls from "local-storage";
import { setAllValue, updateUserInfo } from '../globals/utils';
import { encryptionKey } from '../globals/constants'
import { SignJWT } from 'jose';

export class RestDataSource {
	constructor(baseurl) {
		this.BASE_URL = baseurl;
	}
	async SendRequest(method, url, callback, data = {}) {
		try {
			let req = Axios.request({
				method: method,
				url: url,
				data: data,
				withCredentials: true
			});

			const resp = (await req);
			const respdata = resp.data;
			if (resp.status === 200) {
				const { status, message } = respdata
				if (status === true && message === 'success') {
					if (String(url).search('login/auth') !== -1 || String(url).search('login/applicantAuth') !== -1 || String(url).search('login/vendorAuth') !== -1) {
						const { data: { valid, isBlocked } } = respdata;
						let isAdminOrChp = false;
						let isLoggedByOperations = false;
						let roleCode = '';
						let rolePriority = '';
						if (respdata.data.rolePriorityList && respdata.data.rolePriorityList.length) {
							const rolePriorityList = respdata.data.rolePriorityList
							if (rolePriorityList.includes(respdata.data.roleMap['admin']) || rolePriorityList.includes(respdata.data.roleMap['operationshead']) ||
								rolePriorityList.includes(respdata.data.roleMap['chp'])) {
								isAdminOrChp = true;
							}
							isLoggedByOperations = (rolePriorityList.includes(respdata.data.roleMap['operations']));
							if (respdata.data.roleCodeList.length === 1) {
								roleCode = respdata.data.roleCodeList[0];
								rolePriority = respdata.data.rolePriorityList[0];
							}
						}
						let roleId = '';
						if (roleCode !== '') {
							respdata.data.roleList.forEach(data => {
								if (data.code === roleCode)
									roleId = data.id;
							})
						}
						if (respdata.data.uploadBy && respdata.data.uploadBy === 'candidate') {
							roleCode = 'candidate'
						}

						const token = await setAllValue({
							'isloggedin': false,
							'isForgotPassword': false,
							'clientId': respdata.data.clientId,
							'applicantId': respdata.data.applicantId,
							'firstTimeLogin': respdata.data.firstTimeLogin,
							'loginname': respdata.data.firstname,
							'clientEmail': respdata.data.clientEmail,
							'otpExpiryTime': respdata.data.otpExpiryTime,
							'mobilenumber': respdata.data.mobilenumber,
							'vendorId': respdata.data.vendorId,
							'authenticatorEnabled': respdata.data.authenticatorEnabled,
							'accessibleTabs': respdata.data.accessibleTabs,
							'accessExpiryTime': respdata.data.accessExpiryTime,
							'showBilling': respdata.data.showBilling,
							'faceIdEnabled': true,
							'roleList': respdata.data.roleList,
							'rolePriorityList': respdata.data.rolePriorityList,
							'roleCodeList': respdata.data.roleCodeList,
							'roleMap': respdata.data.roleMap,
							'isLoggedByOperations': isLoggedByOperations,
							'rolePath': '',
							'rolePriority': rolePriority,
							'isAdminOrChp': isAdminOrChp,
							'roleCode': roleCode,
							'roleId': roleId,
							'clientCode': respdata.data.clientCode,
						});
						if (valid === true && isBlocked === false) {
							updateUserInfo(respdata.c1)
						} else {
							console.log('Invalid Credentials')
						}
					}
				} else {
					console.log('Regular flow');
				}
			}
			callback(resp.data);
		} catch (err) {
			console.log('Error in response', err);
			// if (err.response && err.response.status === 401 && err.response.data.message === 'Invalid token') {
			// 	ls.clear();
			// 	document.location.href = '/home';
			// } else if (err.response && err.response.status === 401) {
			// 	ls.clear();
			// 	document.location.href = '/home';
			// } else if (err.response && err.response.status === 500) {
			// 	document.location.href = '/500';
			// }
		}

	}
	async GetData(callback, data = {}, method = "get") {
		this.SendRequest(method, this.BASE_URL, callback, data);
	}
	async GetOne(callback, id) {
		this.SendRequest("get", `{this.BASE_URL}/${id}`, callback);
	}

	async Save(callback, data) {
		this.SendRequest("post", this.BASE_URL, callback, data);
	}

	async Update(callback, data) {
		this.SendRequest("put", `{this.BASE_URL}/${data.id}`, callback, data);
	}

	async Delete(callback, data) {
		this.SendRequest("delete", `{this.BASE_URL}/${data.id}`, callback, data);
	}
}