


import React, { Component } from 'react';
import UserInfo from './UserInfo';
import { restbaseurl } from '../globals/constants';
import { RestDataSource } from '../utils/RestDataSource';
import DialogModal from '../globals/uicomps/DialogModal';
import { BTInputField } from '../globals/uicomps/BTInputField';
import BTSnackbar from '../globals/uicomps/BTSnackbar';
import { getValue } from '../globals/utils';

class UserInfoLayout extends Component {

     constructor(props) {
          super(props);
          this.state = {
               isCommentEditable: false,
               comments: this.props.comments,
               previousComment: this.props.comments,
               addNewComment: false,
               showSupportCaseModal: false,
               supportCaseData: [],
               showSnackMessage: false,
               snackMessage: '',
               snackStatus: '',
               isTopPriority: this.props.isTopPriority,
               roleCode:""
          }
          getValue('roleCode').then((roleCode)=>{
               this.setState({roleCode:roleCode});
          });
     }

     updateNewDetails = (e) => {
          let name = e.target.getAttribute("id");
          let value = e.target.value;
          this.setState({
               [name]: value
          });
     }


     saveComments = () => {
          console.log("saavee")
          this.saveCommentsData = new RestDataSource(`${restbaseurl}/applicantCase/updateComments`);
          this.setState({ loading: true }, () => {
               this.saveCommentsData.GetData(
                    respData => {
                         if (respData.status === true) {
                              this.setState({
                                   isCommentEditable: false,
                                   previousComment: this.state.comments
                              })
                         }
                    },
                    {
                         applicantCaseId: this.props.caseId,
                         comments: this.state.comments,
                    },
                    "POST"
               );
          });
     }


     handleCommentEdit = () => {
          this.setState({
               isCommentEditable: true
          })
     }

     closeEdit = () => {
          this.setState({
               isCommentEditable: false,
               comments: this.state.previousComment,
               addNewComment: false
          })
     }

     handleAddNewComment = () => {
          this.setState({
               addNewComment: true,
               isCommentEditable: true
          })
     }

     showSupportCaseModal = (caseNumber) => {
          this.setState({
               showSupportCaseModal: true
          }, () => this.fetchSupportCaseDetails(caseNumber))
     }

     hideModal = () => {
          this.setState({
               showSupportCaseModal: false
          })
     }

     fetchSupportCaseDetails = (caseNumber) => {
          this.restDataSource = new RestDataSource(`${restbaseurl}/applicantCase/supportCaseDetails`);
          this.setState({ loading: true }, () => {
               this.restDataSource.GetData(
                    respData => {
                         if (respData.status === true) {
                              this.setState({
                                   supportCaseData: respData.data
                              })
                         }
                    },
                    {
                         supportCaseNumber: caseNumber
                    },
                    "POST"
               );
          });
     }

     updateCaseDetails = () => {
          this.restDataSource = new RestDataSource(`${restbaseurl}/applicantCase/updateSupportCaseDetails`);
          this.setState({ loading: true }, () => {
               this.restDataSource.GetData(
                    respData => {
                         if (respData.status === true) {
                              this.setState({
                                   snackMessage: 'Updated Successfully',
                                   showSnackMessage: true
                              }, () => this.hideModal())
                         }
                    },
                    {
                         supportCaseData: this.state.supportCaseData
                    },
                    "POST"
               );
          });
     }

     handleChange = (e) => {
          let supportCaseData = this.state.supportCaseData
          if (e.target.value === 'on') {
               supportCaseData[e.target.id] = !supportCaseData[e.target.id]
          } else {
               supportCaseData[e.target.id] = e.target.value
          }
          this.setState({
               supportCaseData: supportCaseData
          })
     }

     handleTopPriorityChange = (e) => {
          const isChecked = e.target.checked;
          const { caseId } = this.props;
          let priorityChangeReq = new RestDataSource(`${restbaseurl}/applicantCase/updateCasePriority`);
          this.setState({ loading: true }, () => {
               priorityChangeReq.GetData(
                    respData => {
                         if (respData.status === true) {
                              this.setState({
                                   snackMessage: 'Updated Successfully',
                                   showSnackMessage: true,
                                   snackStatus: 'success',
                                   isTopPriority: isChecked,
                              })
                         } else {
                              this.setState({
                                   snackMessage: 'Updated Failed',
                                   showSnackMessage: true,
                                   snackStatus: 'danger'
                              })
                         }
                    },
                    {
                         caseId: caseId,
                         isTopPriority: isChecked
                    },
                    "POST"
               );
          });
     }

     hideSnackbar = () => {
          this.setState({ showSnackMessage: false, snackMessage: '', snackStatus: '' });
     }

     render = () => {
          const { supportCaseData, showSnackMessage, snackMessage, snackStatus, isTopPriority } = this.state
          let userInfo = this.props.userInfo;
          const { t } = this.props
          let currentBody = Object.keys(userInfo).map(key =>
               <UserInfo value={userInfo} key={key} data={key} {...this.props} showSupportCaseModal={this.showSupportCaseModal} />
          );
          let showPriorityCheckbox = false;
          if (this.state.roleCode === 'admin' || this.state.roleCode === 'chp') {
               showPriorityCheckbox = true;
          }
          return (

               this.props.data !== "dateCreated" ?
                    <React.Fragment>
                         {
                              showSnackMessage ? <BTSnackbar msg={snackMessage} status={snackStatus} time="4000" onHide={this.hideSnackbar} /> : ''
                         }
                         <div className="card-body user_info_height">

                              <div className="clr5">
                              </div>
                              <div className="row ">
                                   {currentBody}
                              </div>
                              {showPriorityCheckbox ?
                                   <div className="row">
                                        <div className="col-12 mb10">
                                             <label className="container_Checkbox margin0 roundedcheckbox">{t("topPriority")}
                                                  <input type="checkbox" name="isTopPriority" id="isTopPriority" onChange={this.handleTopPriorityChange} checked={isTopPriority} />
                                                  <span className="checkmark"></span>
                                             </label>
                                        </div>
                                   </div>
                                   : null
                              }
                              <div className="sub-heading1">Comments  {this.state.comments !== null ?
                                   !this.state.isCommentEditable && this.state.comments !== "" ? <img className="right pointer" src={require('../images/icons/icon-edit.svg')} height={24} width={24} onClick={this.handleCommentEdit} alt="icon-edit" /> : null
                                   : null}</div>
                              {
                                   this.state.comments !== null ?
                                        this.state.isCommentEditable ?
                                             <React.Fragment>
                                                  <div className="mt10"></div>
                                                  <textarea className="form-control noresize" style={{ height: "80px" }} id="comments" value={this.state.comments} onChange={(e) => { this.updateNewDetails(e) }} />
                                                  <div className="clr20"></div>
                                                  <div className="row">
                                                       <div className="col-12 col-sm-6"><span className="btn btn_primary block " onClick={this.saveComments}>Save</span></div>
                                                       <div className="col-12 col-sm-6">   <span className="btn btn_secondary block " onClick={this.closeEdit}>Cancel</span></div>
                                                  </div>
                                             </React.Fragment>

                                             :
                                             this.state.comments === "" ?
                                                  <span className="btn btn_secondary block  mt10" onClick={this.handleAddNewComment}>Add Comments</span> :
                                                  <div className="smallfont">
                                                       <div className="mt10"></div>
                                                       {this.state.comments}
                                                  </div>
                                        :

                                        this.state.addNewComment ?
                                             <React.Fragment>
                                                  <div className="mt10"></div>
                                                  <textarea className="form-control noresize" style={{ height: "80px" }} id="comments" value={this.state.comments} onChange={(e) => { this.updateNewDetails(e) }} />
                                                  <div className="clr20"></div>
                                                  <div className="row">
                                                       <div className="col-12 col-sm-6"><span className="btn btn_primary block " onClick={this.saveComments}>Save</span></div>
                                                       <div className="col-12 col-sm-6">   <span className="btn btn_secondary block " onClick={this.closeEdit}>Cancel</span></div>
                                                  </div></React.Fragment> :
                                             <React.Fragment>
                                                  <div className="mt10"></div>
                                                  <span className="btn btn_secondary block " onClick={this.handleAddNewComment}>Add Comments</span></React.Fragment>
                              }
                         </div>
                         <div className="modal-dialog ">
                              <DialogModal
                                   show={this.state.showSupportCaseModal}
                                   header="Support Case Details"
                                   onHide={this.hideModal}
                                   successButtonText="Update"
                                   failureButtonText="Cancel"
                                   successCallback={this.updateCaseDetails}
                                   failureCallback={this.hideModal}
                                   showFooter={true}
                                   disableStaticBackdrop={true}
                                   footerClassName="display-block"
                                   className='cibil-popup'
                              >
                                   <>
                                        <div className="row">
                                             <div className="col-12 col-sm-6 col-md-6">
                                                  <BTInputField label={t("Bgv Id")} type="text" id='externalBgvId' value={supportCaseData.externalBgvId} handleChange={this.handleChange} required={true} />
                                             </div>
                                             <div className="col-12 col-sm-3 col-md-3">
                                                  <label className="container_Checkbox margin0 roundedcheckbox font-16" style={{ lineHeight: '90px' }}>{t('Report Shared')}
                                                       <input type="checkbox" id="isReportShared" className="form-control" onChange={this.handleChange}
                                                            checked={supportCaseData.isReportShared} />
                                                       <span className="checkmark" style={{ marginTop: '33px' }}></span>
                                                  </label>
                                             </div>
                                             <div className="col-12 col-sm-3 col-md-3">
                                                  <label className="container_Checkbox margin0 roundedcheckbox font-16" style={{ lineHeight: '90px' }}>{t('Billed')}
                                                       <input type="checkbox" id="billed" className="form-control" onChange={this.handleChange}
                                                            checked={supportCaseData.billed} />
                                                       <span className="checkmark" style={{ marginTop: '33px' }}></span>
                                                  </label>
                                             </div>
                                        </div>
                                   </>
                              </DialogModal>
                         </div>
                    </React.Fragment>
                    : null
          );
     }

}
export default UserInfoLayout;
