import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { LoadingSpinner } from '../../utils/LoadingSpinner';
import * as ls from "local-storage";
import { Formik } from 'formik';
import { getValue } from '../../globals/utils';


let timeinterval;
export class VerifyOTP extends Component {
     constructor(props) {
          super(props);
          this.state = {
               otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '',
               otpStatus: true,
               count: 0,
               verifiedMessage: '',
               minutes: 0,
               seconds: 0,
               alertMsg: null,
               payloadData:null
          };

     }

     handleChange = (e) => {

          const re = /^\d$/;
          // if value is not blank, then test the regex      
          if (e.target.value === '' || re.test(e.target.value)) {
               //this.setState({value: e.target.value})
               this.setState({ [e.target.id]: e.target.value });
               if (e.target.value.length >= e.target.getAttribute("maxlength") && e.target.id !== "otp6") {
                    e.target.nextElementSibling.focus();
               }
          }

     }



     componentDidMount = async() => {
          console.log("Verify OTP Prop", this.props.match.url);
          getValue().then((payloadData)=>{
               this.setState({"payloadData":payloadData},()=>{
                    this.timer();
               });
          });
          
     }

     time_remaining = (endtime) => {
          let t = Date.parse(endtime) - Date.parse(new Date());
          let seconds = Math.floor((t / 1000) % 60);
          let minutes = Math.floor((t / 1000 / 60) % 60);
          return { 'total': t, 'minutes': minutes, 'seconds': seconds };
     }
     timer = async () => {
          let current_time = Date.parse(new Date().toString());
          let deadline = new Date(current_time + Number(this.state.payloadData['otpExpiryTime']));
          this.update_clock = () => {
               let t = this.time_remaining(deadline);
               this.setState({ minutes: t.minutes, seconds: t.seconds })
               if (t.total <= 0) {
                    clearInterval(timeinterval);
                    this.setState({ verifiedMessage: "Link has expired. Try again" })
                    this.backToLogin();

               }
          }

          this.update_clock();
          timeinterval = setInterval(this.update_clock, 1000);
     }

     resendOTP = () => {
          this.props.resendOTP();
          clearInterval(timeinterval)
          this.timer();
     }

     verifyOTP = () => {
          let otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
          otp = String(otp);
          this.props.verifyOTP(otp)
     }

     isOTPFilled = () => {
          return this.state.otp1.length > 0 && this.state.otp2.length > 0 && this.state.otp3.length > 0 && this.state.otp4.length > 0 && this.state.otp5.length > 0 && this.state.otp6.length > 0
     }

     backToLogin = () => {
          ls.clear();
          this.props.changeStepValue("login")
          // document.location.href="/home/login";

     }

     render = () => {
          return (
               <React.Fragment>
                    {
                         this.state.loading === true ? <LoadingSpinner /> :
                              <React.Fragment>
                                   <div className="sub-heading1 text-center">Hi {this.state.payloadData['loginname']}, Verify OTP to continue</div>
                                   <div className="clr20"></div>
                                   <Formik onSubmit={this.verifyOTP} initialValues={this.initialValues}>
                                        {
                                             ({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                                                  <form onSubmit={handleSubmit} noValidate >
                                                       <div className="form-group">
                                                            <label className="body2 line-height-2">OTP has been sent to {this.state.payloadData['mobilenumber']} and  your registered email address </label>
                                                            <div className="otp-inputs">
                                                                 <input type="text" id="otp1" className="form-control"
                                                                      value={this.state.otp1}
                                                                      autoFocus={true} maxLength={1} onChange={this.handleChange}
                                                                 />
                                                                 <input type="text" id="otp2" className="form-control"
                                                                      value={this.state.otp2} onChange={this.handleChange}
                                                                      maxLength={1} />
                                                                 <input type="text" id="otp3" className="form-control"
                                                                      value={this.state.otp3} onChange={this.handleChange}
                                                                      maxLength={1} />
                                                                 <input type="text" id="otp4" className="form-control"
                                                                      value={this.state.otp4} onChange={this.handleChange}
                                                                      maxLength={1} />
                                                                 <input type="text" id="otp5" className="form-control"
                                                                      value={this.state.otp5} onChange={this.handleChange}
                                                                      maxLength={1} />
                                                                 <input type="text" id="otp6" className="form-control"
                                                                      maxLength={1} value={this.state.otp6} onChange={this.handleChange}
                                                                 />

                                                            </div>
                                                            <div><label className="body2 left mt10">OTP will expire in {this.state.minutes}m {this.state.seconds}s</label>
                                                                 <label className="forgetlink body2 right mt10 pointer" onClick={this.resendOTP}>
                                                                      Resend OTP {(this.state.payloadData['isForgotPassword'] || this.state.payloadData['remainingTries'] == null) ? '' : '( ' + this.state.payloadData['remainingTries'] + ' tries )'}   </label></div>
                                                       </div>
                                                       <div className="clr20"></div>
                                                       <input type="submit" className="btn btn_primary block" value="VERIFY & PROCEED" disabled={!this.isOTPFilled()}></input>
                                                  </form>
                                             )
                                        }
                                   </Formik>
                                   <div className="forgetlink body2 left mt10 pointer" onClick={() => { this.props.changeStepValue("login") }}>Back to Login</div>
                                   {this.state.payloadData['authenticatorEnabled'] ? <div className="forgetlink body2 right mt10 pointer" onClick={() => { this.props.changeStepValue("tryAnotherWays") }}>Try Other Ways</div> : ""}
                                   <div className="clr10"></div>
                                   <div className="clr"></div>

                              </React.Fragment>
                    }


               </React.Fragment>
          );
     }

}

export default withTranslation("translations")(VerifyOTP);


