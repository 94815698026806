import i18n from 'i18next';
import { trans_en } from './locale/en';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
     en: trans_en
};

i18n
     .use(LanguageDetector)
     .init({
          resources,
          lng: "en",
          fallbackLng: 'en',
          ns: ['translations'],
          defaultNS: 'translations',

          keySeparator: false, // we do not use keys in form messages.welcome

          interpolation: {
               escapeValue: false, // react already safes from xss
               formatSeparator: ','
          },

          react: {
               useSuspense: true
          }
     });

export default i18n;