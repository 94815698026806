import React, { Component } from 'react';
import VerificationTypeStatusPanel from './VerificationTypeStatusPanel';
import { withTranslation } from 'react-i18next';
import { Accordion } from "react-bootstrap";
import ConfirmationModal from '../globals/uicomps/ConfirmationModal';
import { restbaseurl } from '../globals/constants';
import { RestDataSource } from '../utils/RestDataSource';
import DialogModal from "../globals/uicomps/DialogModal";
import { SingleDocsUpload } from '../globals/uicomps/SingleDocsUpload';
import Axios from 'axios';
import BTSnackbar from '../globals/uicomps/BTSnackbar';
import UserInfo from '../models/UserInfo';
import { getValue } from '../globals/utils';
class VerificationStatusContainer extends Component {


     constructor(props) {
          super(props);
          this.state = {
               optionClass: false,
               stopCaseConfirmationModal: false,
               showCaseTypeModal: false,
               supportCases: false,
               enableEdit: false,
               showRecomputeCaseModal: false,
               bgvId: '',
               showBgvDocUploadModal: false,
               bgvDocument: '',
               showSnack: false,
               snackMsg: '',
               snackStatus: 'success',
               // postJoining: true,
               payloadData:""
          };
          this.userId = UserInfo.getUserid();
          // this.handleOutsideClick = this.handleOutsideClick.bind(this);

     }

     componentDidMount(){
          getValue().then((pLData)=>{
               this.setState({payloadData:pLData});
          });
     }

     selectPanel = (panelNum) => {
          this.props.setActivePanel(panelNum)
     };

     optionOnClick = (e) => {
          !!e && e.stopPropagation();
          if (!this.state.optionClass) {
               document.addEventListener('click', this.handleOutsideClick, false);
          } else {
               document.removeEventListener('click', this.handleOutsideClick, false);
          }
          this.setState(prevState => ({ optionClass: !prevState.optionClass }));

     }
     handleOutsideClick = (e) => {
          this.optionOnClick();
     }

     confirmSuccessCallback = () => {

          this.stopCase = new RestDataSource(
               `${restbaseurl}/applicantCase/stopCheck`
          );

          this.stopCase.GetData(
               data => {
                    if (data.status === true) {
                         this.setState({
                              stopCaseConfirmationModal: false
                         })
                         this.props.hideSidePanel()
                    }
               },
               { "caseId": this.props.caseId },
               "POST"
          );
     }

     stopCaseConfirmationModal = () => {
          this.setState({
               stopCaseConfirmationModal: true
          })

     }
     showCaseTypeModal = () => {
          this.setState({
               showCaseTypeModal: true
          })
     }

     hideCaseTypeModal = () => {
          this.setState({
               showCaseTypeModal: false
          })
     }

     handleChange = (value) => {
          this.setState({
               supportCases: value,
          })
     }

     saveCaseType = () => {
          this.saveCaseTypeDetails = new RestDataSource(
               `${restbaseurl}/applicantCase/createPostJoiningCases`
          );

          this.saveCaseTypeDetails.GetData(
               data => {
                    if (data.status === true) {
                         this.setState({
                              showCaseTypeModal: false
                         })
                         // this.props.hideSidePanel()
                    }
               },
               { "caseId": this.props.caseId, 'supportCases': this.state.supportCases, bgvId: this.state.bgvId, userId: this.userId },
               "POST"
          );
     }

     enableEdit = () => {
          this.setState({
               enableEdit: true
          })
     }

     showRecomputeCaseModal = () => {
          this.setState({
               showRecomputeCaseModal: true
          })
     }

     handleBgvIdChange = (event) => {
          this.setState({
               bgvId: event.target.value
          })
     }

     recomputeCaseStatus = () => {
          this.recomputeStatus = new RestDataSource(
               `${restbaseurl}/applicantCase/reComputeCaseStatus`
          );
          this.recomputeStatus.GetData(
               data => {
                    if (data.status === true) {
                         this.setState({
                              showRecomputeCaseModal: false
                         })
                         this.props.reRenderSidePanel()
                    }
               },
               { "caseId": this.props.caseId },
               "POST"
          );
     }

     uploadBgvDocModal = () => {
          this.setState({ showBgvDocUploadModal: true })
     }

     hideModal = () => {
          this.setState({ showBgvDocUploadModal: false })
     }

     saveAttachmentDocs = (attachmentFile) => {
          this.setState({ bgvDocument: attachmentFile })
     }

     saveBgvDocument = () => {
          let { bgvDocument } = this.state;
          let url = `${restbaseurl}/applicantCase/uploadBgvDocument`;
          let formData = new FormData(this.form);
          formData.append(`bgvDocument`, bgvDocument.file);
          formData.append('id', this.props.caseId)
          const config = {
               headers: { 'content-type': 'multipart/form-data' },
               withCredentials: true
          }
          this.setState({ loading: true });
          Axios.post(url, formData, config).then(({ data }) => {
               this.setState({
                    loading: false,
                    showBgvDocUploadModal: false,
                    showSnack: true,
                    snackMsg: 'Saved',
                    snackStatus: 'success',
               });
               this.props.reRenderSidePanel();
          }).catch((error) => {
               this.setState({ loading: false });
               console.error(error);
          })
     }

     removeBgvDocs = () => {
          this.setState({ bgvDocument: '' })
     }

     hideSnack = () => {
          this.setState({
               showSnack: false, snackMsg: '', snackStatus: 'success',
          });
     }

     handleDownloadSource = (caseNumber) => {
          let url = `${restbaseurl}/applicantCase/downloadApplicantDocsZip`;
          this.setState({ loading: true });
          Axios.post(url,
               {
                    caseNumber: caseNumber,
                    includeInReport: this.state.IncludeAdditionalDocs
               },
               { responseType: 'blob', withCredentials: true }
          ).then((response) => {
               const url = window.URL.createObjectURL(new Blob([response.data]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', `${caseNumber}.zip`); //or any other extension
               document.body.appendChild(link);
               link.click();
               this.setState({ loading: false, showConfirmationModal: false });
          });
     }

     render = () => {

          const { t } = this.props;
          let { bgvId, showBgvDocUploadModal, bgvDocument, showSnack, snackMsg, snackStatus } = this.state;
          let { showCaseType, bgvDocumentLink, disablePostCreation } = this.props;
          let isAdminOrChp = this.state.payloadData && this.state.payloadData['isAdminOrChp'];
          let dataMap = this.props.verificationDetailMap;
          let elements = Object.keys(dataMap).map((key, i) =>
               <VerificationTypeStatusPanel
                    value={dataMap} key={key} data={key} completeState={this.props.completeState} reRenderSidePanel={this.props.reRenderSidePanel}
                    showDeepTrack={this.props.showDeepTrack} hideDeepTrack={this.props.hideDeepTrack}{...this.props}
                    handle={() => this.selectPanel(i)} active={i === this.props.activePanel} index={i} enableEdit={this.state.enableEdit}
               />
          )
          // let tat = this.props.tat
          return (
               <>
                    {showSnack ? <BTSnackbar msg={snackMsg} status={snackStatus} time="4000" onHide={this.hideSnack} /> : null}
                    <div className="sidepop_body user_info_height">
                         <div className="sub-heading2 pad15 left">{t('Verification Status')} </div>
                         <div className="user-card right">
                              <div className="action-group" ref={node => { this.node = node; }}>
                                   <button className="action-btn" onClick={this.optionOnClick}><span className="bt-icon bt-dribble-bot-v"></span></button>
                                   <div className={this.state.optionClass ? 'active action-option ' : 'action-option '}>
                                        <ul className="downloadul">
                                             <li>
                                                  <span onClick={() => this.handleDownloadSource(this.props.caseRefNumber)} title="Download User Documents">{t("Download User Documents")}</span>
                                             </li>
                                             <li>
                                                  {this.props.evidenceZipLink ? <a href={this.props.evidenceZipLink} title="Download Evidence Documents"  > {t("Download Evidence Documents")}</a> : ''}
                                             </li>
                                             {bgvDocumentLink ?
                                                  <li>
                                                       <a download href={bgvDocumentLink}> Download Bgv Document</a>
                                                  </li> :
                                                  <li onClick={this.uploadBgvDocModal}>
                                                       <span>Upload Bgv Document</span>
                                                  </li>}
                                             {isAdminOrChp ?
                                                  <React.Fragment>
                                                       {showCaseType ?
                                                            <>
                                                                 <li onClick={disablePostCreation ? '' : this.showCaseTypeModal}>
                                                                      <span className={disablePostCreation ? "menu-disabled" : ''}>Mark As Post Joining</span>
                                                                 </li>
                                                            </> : null}
                                                       <li onClick={this.enableEdit}>
                                                            <span>Edit Check</span>
                                                       </li>
                                                       <li onClick={this.showRecomputeCaseModal}>
                                                            <span>Recompute Case Status</span>
                                                       </li>
                                                       <li onClick={this.stopCaseConfirmationModal}>
                                                            <span>Stop Case</span>
                                                       </li>
                                                  </React.Fragment>
                                                  : null}
                                             {this.state.payloadData['roleCode'] === 'reportviewer' ?
                                                  <React.Fragment>
                                                       {/* {bgvDocumentLink ?
                                                            <li>
                                                                 <a download href={bgvDocumentLink}> Download Bgv Document</a>
                                                            </li>
                                                            : null
                                                       } */}
                                                       <li onClick={this.enableEdit}>
                                                            <span>Edit Check</span>
                                                       </li>
                                                       <li onClick={this.showRecomputeCaseModal}>
                                                            <span>Recompute Case Status</span>
                                                       </li>
                                                  </React.Fragment>
                                                  : null
                                             }
                                        </ul>
                                   </div>
                              </div>
                         </div>
                         <div className="clr"></div>
                         {/* <div className="sub-heading2 pad15">{t('Verification Status')}
                         {this.props.documentZipLink ? <a href={this.props.documentZipLink} title="Download User Documents" className="right" target="_blank" > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect width="24" height="24" fill="none" /><path d="M11.559,3.062,8.938.441A1.5,1.5,0,0,0,7.879,0H1.5A1.5,1.5,0,0,0,0,1.5v13A1.5,1.5,0,0,0,1.5,16h9A1.5,1.5,0,0,0,12,14.5V4.124a1.507,1.507,0,0,0-.441-1.062ZM8,1.018a.492.492,0,0,1,.231.131l2.621,2.621A.492.492,0,0,1,10.985,4H8ZM8.5,15h-5v-.973A.994.994,0,0,1,4.539,13,11.149,11.149,0,0,0,6,13.218,11.322,11.322,0,0,0,7.461,13,.994.994,0,0,1,8.5,14.027V15Zm2.5-.5a.5.5,0,0,1-.5.5h-1v-.973a2.092,2.092,0,0,0-.357-1.171A2.008,2.008,0,0,0,7.461,12c-.608,0-.761.218-1.461.218S5.148,12,4.539,12a2.007,2.007,0,0,0-1.682.856A2.089,2.089,0,0,0,2.5,14.027V15h-1a.5.5,0,0,1-.5-.5V1.5A.5.5,0,0,1,1.5,1H7V4.252A.746.746,0,0,0,7.751,5H11ZM3.5,9A2.5,2.5,0,1,0,6,6.5,2.5,2.5,0,0,0,3.5,9Zm4,0A1.5,1.5,0,1,1,6,7.5,1.5,1.5,0,0,1,7.5,9Z" transform="translate(6 4)" fill="#8798ad" /></svg> </a> : ''}
                         {this.props.evidenceZipLink ? <a href={this.props.evidenceZipLink} title="Download Evidence Documents" className="right" target="_blank" > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect width="24" height="24" fill="none" /><path d="M47.935,13.529,45.465,11.06a3.589,3.589,0,1,0-.627.627l2.469,2.469a.222.222,0,0,0,.314,0l.314-.314A.222.222,0,0,0,47.935,13.529Zm-5.267-1.974a2.667,2.667,0,1,1,2.667-2.667A2.667,2.667,0,0,1,42.668,11.555Zm-1.335,1.778h-8a.444.444,0,0,1-.444-.444V1.336a.444.444,0,0,1,.444-.444h4.889V3.78a.665.665,0,0,0,.667.667l3.778,0V3.667a1.34,1.34,0,0,0-.392-.944L39.944.392A1.333,1.333,0,0,0,39,0H33.333A1.338,1.338,0,0,0,32,1.336V12.889a1.333,1.333,0,0,0,1.333,1.333h8a1.33,1.33,0,0,0,1.254-.9,4.442,4.442,0,0,1-.942-.121A.442.442,0,0,1,41.333,13.333ZM39.111.905a.437.437,0,0,1,.206.117l2.331,2.33a.438.438,0,0,1,.117.205H39.111Z" transform="translate(-28 5)" fill="#8798ad" /></svg></a> : ''}
                    </div> */}
                         <div id="pack-accordion">
                              <Accordion activeKey={this.props.activePanel} defaultActiveKey="0">
                                   {elements}
                              </Accordion >
                         </div>
                         <div className="clr"></div>
                         <ConfirmationModal
                              show={this.state.stopCaseConfirmationModal}
                              heading="Are you sure ?"
                              subheading="You want to stop this case"
                              successButtonText="Yes"
                              failureButtonText="No"
                              successCallback={() => { this.confirmSuccessCallback() }}
                              failureCallback={() => { this.setState({ stopCaseConfirmationModal: false }) }}
                              closeCallback={() => { this.setState({ stopCaseConfirmationModal: false }) }}
                         ></ConfirmationModal>

                         <div className="modal-dialog">
                              <ConfirmationModal
                                   successButtonText="Create"
                                   failureButtonText="Cancel"
                                   successCallback={this.saveCaseType}
                                   failureCallback={this.hideCaseTypeModal}
                                   closeCallback={this.hideCaseTypeModal}
                                   show={this.state.showCaseTypeModal}
                                   heading="Mark As Post Joining"
                                   className='sml_confirmation_modal'
                                   disabled={bgvId.length === 0 ? true : false}
                              >
                                   <div className="form-group left mb0 padl0  col-12">
                                        <label className="container_Checkbox margin0 roundedcheckbox left ">Post Joining - Current Employment
                                                  <input type="radio" name="caseType" id="caseTypeId" checked={!!!this.state.supportCases} onChange={() => this.handleChange(false)} />
                                             <span className="checkmark"></span>
                                        </label>
                                        <label className="container_Checkbox margin0 roundedcheckbox left ml20">Post Joining - Support Case
                                                  <input type="radio" name="caseType" checked={!!this.state.supportCases} onChange={() => this.handleChange(true)} />
                                             <span className="checkmark"></span>
                                        </label>
                                        <div className="clr20"></div>
                                        <label>Bgv Id</label>
                                        <div className='clr5'></div>
                                        <input type='text' id='bgvId' onChange={this.handleBgvIdChange} value={bgvId} className={`form-control  `} style={{ width: '250px' }} />
                                   </div>
                                   <div className="clr10"></div>
                                   {/* <div className="col-12 col-sm-6 text-center" style={{ display: "inline-flex" }}>
                                   <span className="btn btn_primary" onClick={this.hideCaseTypeModal}>{t('Cancel')}</span>
                                   <span className="btn btn_primary ml10">{t('Save')}</span>
                              </div> */}
                              </ConfirmationModal>

                              <ConfirmationModal
                                   show={this.state.showRecomputeCaseModal}
                                   heading="Are you sure ?"
                                   subheading="You want to recompute case status"
                                   successButtonText="Yes"
                                   failureButtonText="No"
                                   successCallback={() => { this.recomputeCaseStatus() }}
                                   failureCallback={() => { this.setState({ showRecomputeCaseModal: false }) }}
                                   closeCallback={() => { this.setState({ showRecomputeCaseModal: false }) }}
                              ></ConfirmationModal>
                         </div>
                         <div className="modal-dialog ">
                              <DialogModal
                                   show={showBgvDocUploadModal}
                                   header="Upload Document"
                                   successButtonText="Save"
                                   failureButtonText="Cancel"
                                   onHide={this.hideModal}
                                   showFooter="false"
                                   className='cibil-popup'
                              >
                                   <SingleDocsUpload
                                        allowedDocs='pdf, jpg, jpeg, png'
                                        maxSizeMB={0.1}
                                        allowedDocsList={['pdf', 'jpg', 'jpeg', 'png']}
                                        cbSaveDocument={this.saveAttachmentDocs}
                                        cardsPerRow={1}
                                        includeHeader={false}
                                        headerText=''
                                        uploadMessage="Upload Bgv Document - accepts ( pdf, jpg, jpeg, png )"
                                        isPreview={false}
                                        enableRemoveOption={false}
                                        subHeader=''
                                        uploadedFile={bgvDocument}
                                        isFileUploaded={bgvDocument !== '' ? true : false}
                                        altText='landmark_image'
                                        removeDocument={this.removeBgvDocs}
                                   />
                                   <div className='text-center'>
                                        <span className={`btn btn_primary ${bgvDocument === '' ? 'disabled_btn' : false}`} onClick={this.saveBgvDocument}  >Save</span>
                                   </div>
                              </DialogModal>
                         </div>
                    </div ></>
          );
     }
}
export default withTranslation('translations')(VerificationStatusContainer);