import { useLocation, useMatch, useParams, Link, useNavigate } from 'react-router-dom';

// Functional wrapper to pass hooks into the class component
function CustomRouterHook(Component) {
  return function WrappedComponent(props) {
    const location = useLocation();
    const match = useMatch(location.pathname);  // Example match for path '/user/:id'
    const params = useParams();
    const navigate = useNavigate();

    return <Component {...props} location={location} match={match} params={params} navigate={navigate} />;
  };
}

export const WithRouterHooks = CustomRouterHook;