import React from 'react';
import { withTranslation } from 'react-i18next';
import { restbaseurl } from '../../../globals/constants';
import { RestDataSource } from '../../../utils/RestDataSource';
import { BTInputField } from '../../../globals/uicomps/BTInputField';
import BTSnackbar from '../../../globals/uicomps/BTSnackbar';
import ConfirmationModal from '../../../globals/uicomps/ConfirmationModal';
import Select from "react-select";
import { LoadingSpinner } from "../../../utils/LoadingSpinner"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import BTDatePicker from '../../../globals/uicomps/BTDatePicker';
import UserInfo from '../../../models/UserInfo';
class CaseView extends React.Component {

     constructor(props) {
          super(props);
          this.state = {
               caseDetails: {
               },
               courtDetailsList: [],
               refDetailsList: [],
               identityDetailsList: [],
               packList: [],
               showSnackMessage: false,
               snackMessage: '',
               snackStatus: '',
               isEditable: true,
               loading: false,
               showSaveConfirm: false,
               isReportShared: false
          };
          this.userid = UserInfo.getUserid();
     }

     componentDidMount() {
          this.obtainCaseDetails();
     }

     obtainCaseDetails = () => {
          // console.log(this.props);
          this.caseDetailsReq = new RestDataSource(`${restbaseurl}/applicantCase/obtainCaseDetailsCaseManagement`);
          this.setState({ loading: true }, () => {
               this.caseDetailsReq.GetData(
                    response => {
                         let packList = response.caseDetails.packList.map(packObj => {
                              return { label: packObj.name, value: packObj.id };
                         });
                         this.setState({
                              caseDetails: response.caseDetails,
                              packList: packList,
                              isReportShared: response.caseDetails.isReportShared ? true : false,
                              courtDetailsList: response.courtDetailsList,
                              refDetailsList: response.refDetailsList,
                              identityDetailsList: response.identityDetailsList,
                              loading: false
                         });
                    },
                    { id: this.props.caseId },
                    "POST"
               );
          });
     }

     handleBackClick = () => {
          this.props.cbBackToSearch();
     }

     handleChange = (e) => {
          const key = e.target.getAttribute('name');
          const value = e.target.value;
          console.log('handleChang', key, value);
          this.setState({ caseDetails: { ...this.state.caseDetails, [key]: value } });
     }

     handleDateChange = (key, value) => {
          this.setState({ caseDetails: { ...this.state.caseDetails, [key]: value } });
     }

     // enableEdit = () => {
     //      this.setState({ isEditable: true }, () => {
     //           let inputDateList = document.querySelectorAll('.caseDetails input[type=date]');
     //           inputDateList.forEach((obj) => {
     //                obj.addEventListener('change', this.handleChange);
     //           });
     //      });
     // }

     handleSubmit = () => {
          this.setState({ showSaveConfirm: true });
     }

     saveCaseDetails = () => {
          this.saveCaseDetailsReq = new RestDataSource(`${restbaseurl}/applicantCase/updateCaseDetailsCaseManagement`);
          this.setState({ isEditable: false, loading: true }, () => {
               this.saveCaseDetailsReq.GetData(
                    response => {
                         if (response.saveStatus === true) {
                              this.setState({ showSnackMessage: true, snackMessage: 'Saved successfully', snackStatus: 'success', loading: false });
                         } else {
                              this.setState({ showSnackMessage: true, snackMessage: 'Save failed', snackStatus: 'danger', loading: false });
                         }
                    },
                    {
                         userId: this.userid,
                         id: this.state.caseDetails.id,
                         caseDetails: {
                              firstname: this.state.caseDetails.firstname,
                              lastname: this.state.caseDetails.lastname,
                              dateOfBirth: this.state.caseDetails.dateOfBirth,
                              fatherName: this.state.caseDetails.fatherName,
                              employeeId: this.state.caseDetails.employeeId,
                              externalBgvId: this.state.caseDetails.externalBgvId,
                              pdfReportPath: this.state.caseDetails.pdfReportPath,
                              tat: this.state.caseDetails.tat,
                              dueDate: this.state.caseDetails.dueDate,
                              isReportShared: this.state.caseDetails.isReportShared,
                              interimReportDate: this.state.caseDetails.interimReportDate,
                              insuffClearedDate: this.state.caseDetails.insuffClearedDate,
                              completedDate: this.state.caseDetails.completedDate,
                              dateCreated: this.state.caseDetails.dateCreated,
                              packName: this.state.caseDetails.packName,
                              casePackId: this.state.caseDetails.casePackId,
                              email: this.state.caseDetails.email,
                              phoneNumber: this.state.caseDetails.phoneNumber,
                         },
                         courtDetailsList: this.state.courtDetailsList,
                         refDetailsList: this.state.refDetailsList,
                         identityDetailsList: this.state.identityDetailsList,
                    },
                    "POST"
               );
          });
     }

     hideSnackbar = () => {
          this.setState({ showSnackMessage: false, snackMessage: '', snackStatus: '' });
     }

     cbSaveConfirmSuccess = () => {
          this.setState({ showSaveConfirm: false });
          this.saveCaseDetails();
     }

     cbSaveConfirmFailure = () => {
          this.setState({ showSaveConfirm: false });
     }

     generateCourtList = () => {
          this.courtList = [];
          if (this.state.courtDetailsList.length > 0) {
               this.courtList = this.state.courtDetailsList.map((detailObj, i) => {
                    return (<React.Fragment key={`court.${detailObj.id}.${i}`}>
                         <tr>
                              <td>{i + 1}</td>
                              <td>{detailObj.address}</td>
                              <td>
                                   <input name={`court.${detailObj.id}.${i}`} id={`court.${detailObj.id}.${i}`} type="checkbox" disabled={!this.state.isEditable} onChange={this.handleCourtCheckboxChange} checked={detailObj.regenerateEvidence} />
                              </td>
                              <td>
                                   <BTDatePicker name={`court.${detailObj.id}.${i}`} value={detailObj.verifiedDate} handleDateChange={this.handleCourtChange} showTimeSelect={false} required={true} readOnly={!this.state.isEditable} />
                              </td>
                         </tr>
                    </React.Fragment>)
               })
          }
     }

     handleCourtCheckboxChange = (e) => {
          let key = e.target.getAttribute('name');
          let index = Number(key.split('.')[2]);
          let dataList = this.state.courtDetailsList;
          dataList[index]['regenerateEvidence'] = e.target.checked;

          this.setState({ courtDetailsList: dataList });
     }

     handleCourtChange = (key, value) => {
          // let key = e.target.getAttribute('name');
          let index = Number(key.split('.')[2])
          let dataList = this.state.courtDetailsList;
          dataList[index]['verifiedDate'] = value;

          this.setState({ courtDetailsList: dataList });
     }

     generateReferenceList = () => {
          this.referenceList = [];
          if (this.state.refDetailsList.length > 0) {
               this.referenceList = this.state.refDetailsList.map((detailObj, i) => {
                    return (<React.Fragment key={`reference.${detailObj.id}.${i}`}>
                         <tr>
                              <td>{i + 1}</td>
                              <td>{detailObj.name}</td>
                              <td>
                                   <BTDatePicker name={`reference.${detailObj.id}.${i}`} value={detailObj.verifiedDate} handleDateChange={this.handleReferenceChange} showTimeSelect={false} required={true} readOnly={!this.state.isEditable} />
                              </td>
                         </tr>
                    </React.Fragment>)
               })
          }
     }

     handleReferenceChange = (key, value) => {
          // let key = e.target.getAttribute('name');
          let index = Number(key.split('.')[2])
          let dataList = this.state.refDetailsList;
          dataList[index]['verifiedDate'] = value;

          this.setState({ refDetailsList: dataList });
     }

     generateIdentityList = () => {
          this.identityList = [];
          if (this.state.identityDetailsList.length > 0) {
               this.identityList = this.state.identityDetailsList.map((detailObj, i) => {
                    return (<React.Fragment key={`identity.${detailObj.id}.${i}`}>
                         <tr>
                              <td>{i + 1}</td>
                              <td>{detailObj.name}</td>
                              <td>
                                   <BTDatePicker name={`reference.${detailObj.id}.${i}`} value={detailObj.verifiedDate} handleDateChange={this.handleIdentityChange} showTimeSelect={false} required={true} readOnly={!this.state.isEditable} />
                              </td>
                         </tr>
                    </React.Fragment>)
               })
          }
     }

     handleIdentityChange = (key, value) => {
          // let key = e.target.getAttribute('name');
          let index = Number(key.split('.')[2])
          let dataList = this.state.identityDetailsList;
          dataList[index]['verifiedDate'] = value;

          this.setState({ identityDetailsList: dataList });
     }

     handleIsReportShare = () => {
          let value = !this.state.caseDetails.isReportShared
          this.setState({ caseDetails: { ...this.state.caseDetails, isReportShared: value } });
     }

     handlePackChange = (data) => {
          let value = data.value;
          let label = data.label;
          let tempCaseDetails = this.state.caseDetails;
          tempCaseDetails = { ...tempCaseDetails, casePackId: value, casePackName: label }
          this.setState({ caseDetails: tempCaseDetails });
     }
     onMobileChange = (key, value) => {
          console.log('MobileChange', value);
          let newValue = value.replace('-', '').replace(' ', '');
          this.setState({ caseDetails: { ...this.state.caseDetails, [key]: newValue } });
     }

     render = () => {

          const { t } = this.props;
          this.generateCourtList();
          this.generateReferenceList();
          this.generateIdentityList();
          const caseDetails = this.state.caseDetails;
          return (
               this.state.loading ? <LoadingSpinner /> : <React.Fragment>
                    {
                         this.state.showSnackMessage ? <BTSnackbar msg={this.state.snackMessage} status={this.state.snackStatus} time="4000" onHide={this.hideSnackbar} /> : ''
                    }
                    <ConfirmationModal show={this.state.showSaveConfirm} heading="Are you sure?" subheading="Confirm to persist the changes." successButtonText="Save" failureButtonText="Cancel" successCallback={this.cbSaveConfirmSuccess} failureCallback={this.cbSaveConfirmFailure} closeCallback={this.cbSaveConfirmFailure} ></ConfirmationModal>

                    <div className="caseDetails">
                         <div className="">
                              <h6 className="case-view-heading">Case Details</h6>&nbsp;<span className="case-view-sub-heading">({caseDetails.caseNumber})</span>
                              <img alt="icon-close" src={require('../../../images/icons/icon-close.svg')} onClick={this.handleBackClick} className="right pointer" />
                              <div className="form-group case-management-report-share">
                                   <label className="container_Checkbox roundedcheckbox mb0">Report Shared
                                                  <input type="checkbox" id="checkbox" onChange={this.handleIsReportShare} checked={!!caseDetails.isReportShared} disabled={!this.state.isEditable} />
                                        <span className="checkmark"></span>
                                   </label>
                              </div>
                         </div>
                         <div className="clr10"></div>
                         <div className="row white-bg">
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTInputField label={t("First Name")} type="text" id="firstname" value={caseDetails.firstname} readonly={!this.state.isEditable} handleChange={this.handleChange} required={true} />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTInputField label={t("Last Name")} type="text" id="lastname" value={caseDetails.lastname} readonly={!this.state.isEditable} handleChange={this.handleChange} required={true} />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <div className='form-group'>
                                        <label className="body2 left">Email</label>
                                        <span className="title-hover mr0 ml10" >
                                             <img src={"/images/icons/icon-info.svg"} height="18" className='mr20 right' alt="pack-info" />
                                             <div className="icon-details pad10">
                                                  <div className="">Changes made to email will not trigger candidate invite to the new mail id</div>
                                             </div>
                                        </span>
                                        <input type='text' id="email" name='email' className='form-control' value={caseDetails.email} readOnly={!this.state.isEditable} onChange={this.handleChange} />
                                   </div>
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <div className={`form-group ${!!this.state.isEditable ? '' : 'disabled'}`}>
                                        <label className="body2">{t("Mobile Number")}</label>
                                        <PhoneInput country={'in'} value={caseDetails.phoneNumber} id='phoneNumber' name="phoneNumber" onChange={(value) => this.onMobileChange('phoneNumber', value)} enableAreaCodes={false} />
                                   </div>
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 formgroupmargin">
                                   <BTDatePicker label={t("Date of Birth")} name={`dateOfBirth`} value={caseDetails.dateOfBirth} handleDateChange={this.handleDateChange} showTimeSelect={false} required={true} readOnly={!this.state.isEditable} />
                                   <label className="note">*If you update  DOB , you have to regenerate court evidence</label>
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTInputField label={t("Father Name")} type="text" id="fatherName" value={caseDetails.fatherName} readonly={!this.state.isEditable} handleChange={this.handleChange} required={true} />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTInputField label={t("Employee Id")} type="text" id="employeeId" value={caseDetails.employeeId} readonly={!this.state.isEditable} handleChange={this.handleChange} required={true} />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTInputField label={t("Applicant Id")} type="text" id="externalBgvId" value={caseDetails.externalBgvId} readonly={!this.state.isEditable} handleChange={this.handleChange} required={true} />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTInputField label={t("PDF Report Path")} type="text" id="pdfReportPath" value={caseDetails.pdfReportPath} readonly={!this.state.isEditable} handleChange={this.handleChange} required={true} />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTInputField label={t("TAT (in mins.)")} type="text" id="tat" value={caseDetails.tat} readonly={!this.state.isEditable} handleChange={this.handleChange} required={true} />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTDatePicker label={t("Due Date")} name={`dueDate`} value={caseDetails.dueDate} handleDateChange={this.handleDateChange} showTimeSelect={false} required={true} readOnly={!this.state.isEditable} />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTDatePicker label={t("Interim Report Date")} name={`interimReportDate`} value={caseDetails.interimReportDate} handleDateChange={this.handleDateChange} showTimeSelect={false} required={true} readOnly={!this.state.isEditable} />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTDatePicker label={t("Insuff Cleared Date")} name={`insuffClearedDate`} value={caseDetails.insuffClearedDate} handleDateChange={this.handleDateChange} showTimeSelect={false} required={true} readOnly={!this.state.isEditable} />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTInputField label={t("Pack Name")} type="text" id="packName" value={caseDetails.packName} readonly={!this.state.isEditable} handleChange={this.handleChange} required={true} />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <div className="form-group">
                                        <label>{t("Choose a Pack")}</label>
                                        <Select
                                             id="casePackId"
                                             name="casePackId"
                                             placeholder='Select Pack'
                                             className={this.state.isEditable ? '' : 'disabled'}
                                             style={{ border: "2px solid red", zIndex: "1000" }}
                                             options={this.state.packList}
                                             defaultValue={{ label: caseDetails.casePackName, value: caseDetails.casePackId }}
                                             onChange={this.handlePackChange}
                                        />
                                   </div>
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTDatePicker label={t("Report Completed Date")} name={`completedDate`} value={caseDetails.completedDate} handleDateChange={this.handleDateChange} showTimeSelect={false} required={true} readOnly={!this.state.isEditable} />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                   <BTDatePicker label={t("Case Created Date")} name={`dateCreated`} value={caseDetails.dateCreated} handleDateChange={this.handleDateChange} showTimeSelect={false} required={true} readOnly={!this.state.isEditable} />
                              </div>
                         </div>
                    </div>
                    {
                         this.courtList.length > 0 ?
                              <React.Fragment>
                                   <div className="">
                                        <div>
                                             <h6 className="case-view-heading">Court Details</h6>
                                        </div>
                                        <div className="clr10"></div>
                                        <div className="row">
                                             <div className="col-12">
                                                  <table>
                                                       <tbody>
                                                            <tr>
                                                                 <th>S.No.</th>
                                                                 <th>Address</th>
                                                                 <th>Re-generate evidence</th>
                                                                 <th>Verified Date</th>
                                                            </tr>
                                                            {this.courtList}
                                                       </tbody>
                                                  </table>
                                                  {/* <div className="col-12 col-sm-6 col-md-6">
                                        <BTInputField label={t("Verified Date")} type="date" id="courtVerifiedDate" value={caseDetails.courtVerifiedDate} readonly={!this.state.isEditable} handleChange={this.handleChange} required={true} />
                                   </div> */}
                                             </div>
                                        </div>
                                   </div>
                                   <div className="clr10"></div>
                              </React.Fragment>
                              : null
                    }
                    {
                         this.referenceList.length > 0 ?
                              <React.Fragment>
                                   <div className="">
                                        <div>
                                             <h6 className="case-view-heading">Reference Details</h6>
                                        </div>
                                        <div className="clr10"></div>
                                        <div className="row">
                                             <div className="col-12">
                                                  <table>
                                                       <tbody>
                                                            <tr>
                                                                 <th>S.No.</th>
                                                                 <th>Name</th>
                                                                 <th>Verified Date</th>
                                                            </tr>
                                                            {this.referenceList}
                                                       </tbody>
                                                  </table>
                                                  {/* <div className="col-12 col-sm-6 col-md-6">
                                        <BTInputField label={t("Verified Date")} type="date" id="courtVerifiedDate" value={caseDetails.courtVerifiedDate} readonly={!this.state.isEditable} handleChange={this.handleChange} required={true} />
                                   </div> */}
                                             </div>
                                        </div>
                                   </div>
                                   <div className="clr10"></div>
                              </React.Fragment>
                              : null
                    }
                    <div className="clr20"></div>
                    {
                         this.identityList.length > 0 ?
                              <React.Fragment>
                                   <div className="">
                                        <div>
                                             <h6 className="case-view-heading">Identity Details</h6>
                                        </div>
                                        <div className="clr10"></div>
                                        <div className="row white-bg">
                                             <div className="col-12">
                                                  <table>
                                                       <tbody>
                                                            <tr>
                                                                 <th>S.No.</th>
                                                                 <th>Type</th>
                                                                 <th>Verified Date</th>
                                                            </tr>
                                                            {this.identityList}
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="clr20"></div>
                              </React.Fragment>
                              : null
                    }
                    <div className="col-12 text-align-right">
                         {
                              <span className="btn btn_primary" onClick={this.handleSubmit}>Save</span>
                              // : <span className="btn btn_primary" onClick={this.enableEdit}>Edit</span>
                         }
                    </div>
               </React.Fragment >
          );
     }
}

export default withTranslation('translations')(CaseView);