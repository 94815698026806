import React from 'react';
import { withTranslation } from 'react-i18next';
import { restbaseurl } from "../../../globals/constants";
import { RestDataSource } from "../../../utils/RestDataSource";
import Select from "react-select";
import { LoadingSpinner } from '../../../utils/LoadingSpinner';
import VendorResidenceAssignment from './VendorResidenceAssignment';
import UserInfo from '../../../models/UserInfo';
import { getValue } from '../../../globals/utils';
import VendorDrugAssignment from './VendorDrugAssignment';
class VendorAssignmentLayout extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               loading: false,
               clientName: "",
               clientId: '',
               showError: false,
               dummy: false,
               selectedCheck: '',
               checkName: "",
               checkId: '',
               showDetails: false,
               details: '',
               selectedCheckLabel: '',
               unAssignedList: [],
               totalCount: -1,
               checkCode: ''
          };
          this.checkId = '';
          this.clientList = [];
          this.checkList = [];
          this.defObjList = [];
          this.defObjCheck = [];
          this.init();
          this.userid = UserInfo.getUserid();
     }

     componentDidMount = async () => {
          this.setState({clientId:await getValue('clientId')},()=>{
               this.searchClientName()
               this.searchCheckList()
          })
     }

     init = () => {
          if (this.state.clientName !== '' && this.state.clientName !== null && this.state.clientName !== undefined) {
               this.defObjList = []
               this.defObjList.push({ value: this.state.clientId, label: this.state.clientName });
          }
          if (this.state.checkName !== '' && this.state.checkName !== null && this.state.checkName !== undefined) {
               this.defObjCheck = []
               this.defObjCheck.push({ value: this.state.checkId, label: this.state.checkName });
          }
     }

     searchClientName = () => {
          this.clientDetails = new RestDataSource(
               `${restbaseurl}/user/clientList`
          );
          this.setState({ loading: true }, async () => {
               this.clientDetails.GetData(
                    data => {
                         this.setState({ loading: false });
                         if (data.status === true) {
                              this.setState({
                                   details: data.data,
                              });
                              let clientList = data.data.clientList
                              this.clientList = clientList.map(data => { return { label: data.clientName, id: data.clientId } });
                         }
                         this.setState({
                              dummy: !this.state.dummy
                         })
                    },
                    { userId: this.userid,roleId:await getValue('roleId') },
                    "POST"
               );
          });
     }

     searchCheckList = () => {
          this.checkDetails = new RestDataSource(
               `${restbaseurl}/user/obtainVendorVerificationList`
          );
          this.setState({ loading: true }, () => {
               this.checkDetails.GetData(
                    respData => {
                         this.setState({ loading: false });
                         let verificationTypeList = respData.verificationTypeList
                         this.checkList = verificationTypeList.map(checkType => { return { label: checkType.name, id: checkType.id } });
                         this.setState({
                              dummy: !this.state.dummy
                         })
                    },
                    "POST"
               );
          });
     }


     onClientChange = (event) => {
          this.setState({
               clientName: event.label,
               clientId: event.id,
               showError: false
          })
          this.setState({clientId : event.id});
     }

     onCheckChange = (event) => {
          this.checkId = event.id;
          this.setState({
               checkName: event.label,
               selectedCheckLabel: event.label,
               checkId: event.id,
               showError: false
          }
               , () => {
                    this.fetchCaseDetails();
               })
     }


     fetchCaseDetails = () => {
          if (this.state.selectedCheckLabel === '' || this.state.checkName === '' || this.state.clientId === '') {
               this.setState({
                    showError: true
               })
          }
          else {
               this.checkDetails = new RestDataSource(
                    `${restbaseurl}/user/showUnassignedVerificationDetail`
               );
               this.setState({ loading: true }, () => {
                    this.checkDetails.GetData(
                         data => {
                              this.setState({
                                   loading: false,
                                   showDetails: true,
                                   unAssignedList: data.data.detail,
                                   showNoData: false,
                                   selectedCheck: this.state.selectedCheckLabel,
                                   totalCount: data.data.detail.length,
                                   checkCode: data.data.code
                              });
                         },
                         { clientId: this.state.clientId, checkId: this.checkId, showUnAssignedVendor: true },
                         "POST"
                    );
               });
          }
     }


     render = () => {
          this.init()
          // let selectedCheck = this.state.selectedCheck
          let { totalCount, checkCode } = this.state;

          return (
               this.state.loading ? <LoadingSpinner /> : <React.Fragment>
                    <div className="clr20"></div>
                    <div className="row">
                         <div className="col-sm-12 col-md-4">
                              <div className="form-group" style={{ zIndex: "1000" }}>
                                   <div className="sub-heading1 mb10">Choose a Client</div>
                                   <Select
                                        id="clientList"
                                        placeholder="Search clients"
                                        style={{ border: "2px solid red", zIndex: "1000" }}
                                        options={this.clientList}
                                        defaultValue={this.defObjList}
                                        onChange={this.onClientChange}
                                   ></Select>
                              </div>
                         </div>
                         <div className="col-sm-12 col-md-4">
                              <div className="form-group" style={{ zIndex: "1000" }}>
                                   <div className="sub-heading1 mb10">Select a Check</div>
                                   <Select
                                        id="checkList"
                                        placeholder="Select check"
                                        style={{ border: "2px solid red", zIndex: "1000" }}
                                        options={this.checkList}
                                        defaultValue={this.defObjCheck}
                                        onChange={this.onCheckChange}
                                   ></Select>
                              </div>
                         </div>

                         <div className="col-4 " style={{ marginTop: "30px" }}>
                              <span
                                   className="btn btn_primary ml20"
                                   onClick={this.fetchCaseDetails}
                              >Show UnAssigned </span>
                         </div>
                    </div>
                    <div className="clr20"></div>
                    {
                         (this.state.showDetails === true) ?
                             ( this.checkId === 4  || this.checkId === 6)?
                                   <VendorResidenceAssignment checkCode={checkCode} unAssignedList={this.state.unAssignedList} cbRender={this.fetchCaseDetails} totalCount={totalCount} chooseVendor={true} completeState={this.state} />
                                   :
                                   this.checkId === 5 ?
                                        <VendorDrugAssignment checkCode={checkCode} unAssignedList={this.state.unAssignedList} cbRender={this.fetchCaseDetails} totalCount={totalCount} completeState={this.state} />
                                        : null
                              :
                              null
                    }
                    {
                         this.state.showError ?
                              <div className="showerrormsg">
                                   <span>Please fill all the details</span>  </div> : ""
                    }
               </React.Fragment>
          );
     }
}

export default withTranslation('translations')(VendorAssignmentLayout);