import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { BTFieldValError } from './BTFieldValError';
import "react-datepicker/dist/react-datepicker.css";
import '../../css/datepicker_custom.css';
import CustomErrorMessage from 'globals/CustomErrorMessage';
import * as yup from "yup";

export default class BTDatePicker extends Component {
     
     constructor(props){
          super(props);
          this.validationSchema = yup.object({
               [this.props.name]: yup.date().required('Date is required').nullable(),
             });
          this.state = {
               formData:{
                    [this.props.name]: null,
               },
               errors:{
                    [this.props.name]: '',
               },
               touched: {
                    [this.props.name]: false,
               }
          }
     }

     handleDateChange = (value, key) => {
          const { showTimeSelect, handleDateChange } = this.props;
          let changedDateValue = "";
          if (value === null) {
               handleDateChange(key, '');
          } else if (showTimeSelect) {
               const date = new Date(value);
               const mnth = (`0${date.getMonth() + 1}`).slice(-2);
               const day = (`0${date.getDate()}`).slice(-2);
               const hours = date.getHours();
               const minutes = date.getMinutes();
               const trimmedDate = [date.getFullYear(), mnth, day].join('-');
               const trimmedTime = [hours, minutes].join(':');
               const trimmedDateTime = `${trimmedDate} ${trimmedTime}`;
               changedDateValue = trimmedDateTime;
               handleDateChange(key, trimmedDateTime);
          } else {
               const date = new Date(value);
               const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
               const day = ("0" + date.getDate()).slice(-2);
               const trimmedDate = [date.getFullYear(), mnth, day].join("-");
               changedDateValue = trimmedDate;
               handleDateChange(key, trimmedDate);
          }
          this.setState({
               formData:{
                    [this.props.name]: changedDateValue
               }
          });
     }

     
     // Handle blur (field losing focus)
     handleBlur = (e) => {
          this.setState({
               touched:{
                    [this.props.name]: true
               }
          });
          this.validate();
     };

     handleFocus = (e)=>{
          this.setState({
               touched:{
                    [this.props.name]: false
               },
               errors:{
                    [this.props.name]: ''
               }
          });
     }

     // Custom validation logic
     validate = () => {
          let newErrors = {};
          try {
          this.validationSchema.validateSync(this.state.formData, { abortEarly: false });
          } catch (err) {
          err.inner.forEach((error) => {
          newErrors[error.path] = error.message;
          });
          }
          this.setState({errors:newErrors});
          return newErrors;
     };

     render = () => {
          let customClass = this.props.customClass;
          let { value, name, placeholderText, showTimeSelect, readOnly, minDate, maxDate, label, required, customClassName, dateFormat } = this.props;
          if (minDate) {
               minDate = new Date(minDate);
          }
          if (maxDate) {
               maxDate = new Date(maxDate);
          }
          placeholderText = placeholderText ? placeholderText : "yyyy-mm-dd";
          if (value === 'undefined') {
               value = ''
          }
          value = value ? value : '';
          return (
               <div className={`form-group`}>
                    {label ? <label className="body2">{label}
                         {
                              !required ? <div className="optional">&nbsp;( optional )</div> : null
                         }
                    </label> : null}
                    <DatePicker
                         selected={value ? new Date(value) : ''}
                         timeFormat="HH:mm"
                         onChange={value => this.handleDateChange(value, name)}
                         onBlur={this.handleBlur}
                         onFocus={this.handleFocus}
                         showMonthDropdown
                         showYearDropdown
                         dropdownMode="select"
                         isClearable={readOnly ? false : true}
                         showTimeSelect={showTimeSelect}
                         placeholderText={placeholderText}
                         value={value ? new Date(value) : ''}
                         minDate={minDate ? minDate : ''}
                         maxDate={maxDate ? maxDate : ''}
                         timeIntervals={15}
                         timeCaption="Time"
                         dateFormat={dateFormat || 'yyyy-MM-dd'}
                         autoComplete='off'
                         name={name}
                         readOnly={readOnly ? readOnly : false}
                         className={`form-control ${customClass ? customClassName : ''}`}
                    />
                    <CustomErrorMessage name={name} errors={this.state.errors} touched={this.state.touched} CustomComponent={BTFieldValError} />
               </div>
          );
     }
}
