import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { LoadingSpinner } from '../utils/LoadingSpinner';
import { getValue } from '../globals/utils';
import * as ls from "local-storage";

export class TryAnotherWays extends Component {
     constructor(props) {
          super(props);
          this.state = {
               payloadData:null
          };
          getValue().then((pData)=>{ 
               console.log("pData:",pData);
               if(pData){
                    this.setState({
                         isAuthMFAEnabled: pData['authenticatorEnabled'],
                         isAuthPhotoEnabled: pData['faceIdEnabled'],
                         alertMsg: null,
                         payloadData: pData
                    });
               }else{
                    this.backToLogin();
               }
              
              
          });
          

     }

     backToLogin = () => {
          ls.clear();
          this.props.changeStepValue("login");
     }


     render = () => {

          return (
               <React.Fragment>
                    { 
                         this.state.payloadData === null ? <LoadingSpinner /> :
                              <React.Fragment>
                                   <div className="anotherways">
                                        <div className="sub-heading1 text-center">Hi {this.state.payloadData['loginname']}, Welcome back</div>
                                        <div className="clr20"></div>
                                        <div className="form-group">
                                             <label className="sub-heading">Choose one of these ways to sign in</label>

                                             <div>
                                                  <ul>
                                                       <li className="anotherway-list"><img src={"/images/icons/icon-otp.svg"} alt="icon-otp"
                                                            className="anotherway-icon" /> <span onClick={() => { this.props.changeStepValue("verifyOtp") }}>OTP verification</span></li>

                                                       {this.state.isAuthPhotoEnabled ?
                                                            <li className="anotherway-list"><img src={"/images/icons/icon-face.svg"} alt="icon-face"
                                                                 className="anotherway-icon" /> <span onClick={() => { this.props.changeStepValue("photoAuthenticator") }}>Face detection</span></li>
                                                            : ""}
                                                       {this.state.isAuthMFAEnabled ?
                                                            <li className="anotherway-list"><img src={"/images/icons/icon-mfa.svg"} alt="icon-mfa"
                                                                 className="anotherway-icon" /> <span onClick={() => { this.props.changeStepValue("authenticator") }}>MFA code</span></li>
                                                            : ""}
                                                  </ul>
                                             </div>


                                             <div className="clr20"></div>

                                             <div className="clr"></div>

                                             <span className="forgetlink body2 right sub-heading" onClick={() => { this.props.changeStepValue("login") }}>Sign in with a different account</span>

                                        </div>


                                        <div className="clr"></div>
                                   </div>
                              </React.Fragment>
                    }


               </React.Fragment>
          );
     }

}

export default withTranslation("translations")(TryAnotherWays);


