import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { setValue } from '../../globals/utils';

class ClientRow extends Component {
     
     casesDashboard = async (id, clientName, clientCode) => {
          await setValue('clientId', id);
          await setValue('clientName', clientName);
          await setValue('clientCode', clientCode);
          this.props.cbChangeBaseTab('clientCases');
          // window.location.href = '/reportViewerDashboard/clientCases';
     }

     render = () => {
          const { index, caseList } = this.props;
          return (
               <>
                    <tr>
                         <td>{index}</td>
                         <td>
                              <div
                                   className="clientName pointer button_buttonType" role="button" tabIndex="0" onClick={() => { this.casesDashboard(caseList.clientId, caseList.clientName, caseList.clientCode); }}
                                   onKeyDown={() => { }}
                              >{caseList.clientName}
                              </div>
                         </td>
                         <td> {caseList.withinTat ? caseList.withinTat : 0}</td>
                         <td>{caseList.beyondTat ? caseList.beyondTat : 0}</td>
                         <td>{caseList.pending ? caseList.pending : 0}</td>
                         <td>{caseList.completed ? caseList.completed : 0}</td>
                         <td>{caseList.total}</td>
                    </tr>

               </>
          );
     }
}

export default withTranslation('translations')(ClientRow);
