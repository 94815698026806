import React from 'react';

const CustomErrorMessage = ({ name, errors, touched, CustomComponent }) => {
  // console.log("name, errors, touched:",name, errors, touched);
  
  if (!touched || !touched[name] || !errors[name]) {
    return null; // Don't show error if field is not touched or no error exists
  }
  
  return <CustomComponent>{errors[name]}</CustomComponent>;
};

export default CustomErrorMessage;