import React from 'react';
import { withTranslation } from 'react-i18next';
import { restbaseurl } from "../../../../globals/constants";
import { RestDataSource } from "../../../../utils/RestDataSource";
import Select from "react-select";
import { LoadingSpinner } from '../../../../utils/LoadingSpinner';
import Axios from 'axios';
import UserInfo from '../../../../models/UserInfo';
import { getValue } from '../../../../globals/utils';
import BTDatePicker from '../../../../globals/uicomps/BTDatePicker';
import makeAnimated from 'react-select/animated';
import VendorCostingTableRow from './VendorCostingTableRow';
import { BTSelectField } from '../../../../globals/uicomps/BTSelectField';
import ConfirmationModal from '../../../../globals/uicomps/ConfirmationModal';
import BTSnackbar from '../../../../globals/uicomps/BTSnackbar';
class VendorCostingLayout extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               loading: false,
               clientName: "",
               clientId: '',
               showError: false,
               dummy: false,
               selectedCheck: '',
               checkName: "",
               checkId: '',
               showDetails: false,
               details: '',
               selectedCheckLabel: '',
               showTable: false,
               unAssignedList: [],
               totalCount: 0,
               vendorLabel: '',
               vendorId: null,
               checkCode: '',
               checkDetailsList: [],
               selectedCaseIdList: [],
               filterObj: {
                    clientIdList: [],
                    vendorIdList: [],
                    checkId: '',
                    searchString: '',
                    startDate: '',
                    toDate: '',
                    selectedVendorOptionList: [],
                    selectedClientOptionList: [],
                    isPaid: false,
                    stage: ''
               },
               modalHeading: '',
               modalSubHeading: '',
               showAddCostModal: false,
               cost: 0,
               isPaidModal: false,
               showSnackMessage: false,
               snackMessage: '',
               snackStatus: '',
          };
          this.checkId = ''
          this.vendorId = null
          this.clientList = []
          this.checkList = []
          this.defObjList = []
          this.defObjCheck = []
          this.defObjVendor = []
          this.vendorOptions = []
          this.stageOptionTags = []
          this.init();
          this.userid = UserInfo.getUserid()
     }

     componentDidMount = async () => {
          this.setState({clientId:await getValue('clientId')},()=>{
               this.generateStageOptionList();
               this.fetchClientList();
               this.fetchCheckList();
          });
     }

     generateStageOptionList = () => {
          const stageOptionList = ['Choose a Stage', 'Stage 1', 'Stage 2', 'Stage 3']
          this.stageOptionTags = stageOptionList.map(row => {
               return <option key={row} value={row === 'Choose a Stage' ? '' : row}>{row}</option>
          });
     }

     init = () => {
          if (this.state.clientName !== '' && this.state.clientName !== null && this.state.clientName !== undefined) {
               this.defObjList = []
               this.defObjList.push({ value: this.state.clientId, label: this.state.clientName });
          }
          if (this.state.checkName !== '' && this.state.checkName !== null && this.state.checkName !== undefined) {
               this.defObjCheck = []
               this.defObjCheck.push({ value: this.state.checkId, label: this.state.checkName });
          }
          if (this.state.vendorLabel !== '' && this.state.vendorLabel !== null && this.state.vendorLabel !== undefined) {
               this.defObjVendor = []
               this.defObjVendor.push({ value: this.state.vendorId, label: this.state.vendorLabel });
          }
     }

     fetchClientList = () => {
          this.clientDetails = new RestDataSource(
               `${restbaseurl}/user/clientList`
          );
          this.setState({ loading: true }, async () => {
               this.clientDetails.GetData(
                    data => {
                         this.setState({ loading: false });
                         if (data.status === true) {
                              this.setState({
                                   details: data.data,
                              });
                              let clientList = data.data.clientList
                              this.clientList = clientList.map(data => { return { label: data.clientName, value: data.clientId } });
                         }
                         this.setState({
                              dummy: !this.state.dummy
                         })
                    },
                    { userId: this.userid, roleId: await getValue('roleId') },
                    "POST"
               );
          });
     }

     fetchVendorList = (id) => {
          this.vendorOptions = []
          this.defObjVendor = []
          this.loadList = new RestDataSource(
               `${restbaseurl}/applicantCase/vendorList`
          );
          this.setState({ loading: true }, () => {
               this.loadList.GetData(
                    data => {
                         if (data.status === true) {
                              this.vendorOptions = data.data.map(vendorObj => {
                                   return { label: vendorObj.vendorName, value: vendorObj.vendorId };
                              });
                              this.vendorOptions.shift();
                         }
                         this.setState({ loading: false, });
                         // this.setState({
                         //      dummy: !this.state.dummy
                         // })
                    },
                    { verificationCode: id },
                    "POST"
               );
          });
     }

     fetchCheckList = () => {
          this.checkDetails = new RestDataSource(
               `${restbaseurl}/user/obtainVendorVerificationList`
          );
          this.setState({ loading: true }, () => {
               this.checkDetails.GetData(
                    respData => {
                         this.setState({ loading: false });
                         let verificationTypeList = respData.verificationTypeList
                         this.checkList = verificationTypeList.map(checkType => { return { label: checkType.name, value: checkType.id, id: checkType.code, verificationCode: checkType.verificationCode } });
                         this.setState({
                              dummy: !this.state.dummy
                         })
                    },
                    "POST"
               );
          });
     }


     onCheckChange = (event) => {
          const { filterObj } = this.state;
          this.checkId = event.value
          this.setState({
               checkName: event.label,
               selectedCheckLabel: event.label,
               checkId: event.value,
               checkCode: event.verificationCode,
               showTable: false,
               filterObj: {
                    ...filterObj,
                    checkId: event.value
               }
          })
          this.fetchVendorList(event.verificationCode)

     }

     onChangeCandidate = (event) => {
          this.setState({
               vendorLabel: event.label,
               vendorId: event.value,
               showError: false
          })
          this.vendorId = event.value
     }

     fetchCaseDetails = (filterObj) => {
          filterObj.userId = this.userid
          this.vendorDetails = new RestDataSource(
               `${restbaseurl}/vendor/obtainVendorCompletedDetails`
          );
          this.setState({ loading: true }, () => {
               this.vendorDetails.GetData(
                    data => {
                         this.setState({
                              loading: false,
                              showTable: true,
                              checkDetailsList: data.data,
                              totalCount: data.data.length,
                              selectedCaseIdList: [],
                              isPaid: false
                         });
                    },
                    { ...filterObj },
                    "POST"
               );
          });
     }

     downloadVendorXLS = () => {
          const { filterObj } = this.state;
          let url = `${restbaseurl}/vendor/downloadVendorCostXls`;
          this.setState({ loading: true });
          const config = {
               responseType: 'blob',
               withCredentials: true
          }
          Axios.post(url,
               {
                    filterObj: filterObj,
                    showUnAssignedVendor: false,
                    userId: this.userId,
               },
               config
          ).then((response) => {
               const url = window.URL.createObjectURL(new Blob([response.data]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', 'VendorDetails.xlsx'); //or any other extension
               document.body.appendChild(link);
               link.click();
               this.setState({loading: false});
          }).catch((error) => {
               this.setState({loading: false, showSnackMessage: true, snackMessage: 'Failed to download Xls', snackStatus: 'danger'});
          });
     }

     onMultiSelectChange = (event, selectedOptionList, selectedList) => {
          const { filterObj } = this.state;
          var selected = []
          var selectedOption = []
          if (event !== null) {
               event.forEach((data) => {
                    selected.push(data.value)
                    selectedOption.push({ label: data.label, value: data.value })
               })
          }
          this.setState({
               filterObj: {
                    ...filterObj,
                    [selectedOptionList]: selectedOption,
                    [selectedList]: selected
               }
          })
     }

     handleValueChange = (key, value) => {
          const { filterObj } = this.state;
          this.setState({
               filterObj: {
                    ...filterObj,
                    [key]: value
               }
          })
     }

     handleSelectAll = (isSelected, caseIdList) => {
          let { selectedCaseIdList } = this.state;
          if (isSelected) {
               selectedCaseIdList = []
          } else {
               selectedCaseIdList = caseIdList
          }
          this.setState({ selectedCaseIdList })
     }

     handleSelectChange = (id) => {
          let { selectedCaseIdList } = this.state;
          selectedCaseIdList.includes(id) ? selectedCaseIdList = selectedCaseIdList.filter(data => data !== id) : selectedCaseIdList.push(id)
          this.setState({ selectedCaseIdList })
     }

     costUpdation = () => {
          let { filterObj, selectedCaseIdList, cost, isPaidModal } = this.state;
          let action = '';
          if (isPaidModal) {
               action = 'updateVendorCostPaid';
          } else {
               action = 'updateVendorCost';
          }
          filterObj.userId = this.userid;
          filterObj.cost = cost;
          filterObj.isPaid = isPaidModal;
          filterObj.selectedCaseIdList = selectedCaseIdList;
          this.updateCost = new RestDataSource(
               `${restbaseurl}/vendor/${action}`
          );
          this.setState({ loading: true }, () => {
               this.updateCost.GetData(
                    data => {
                         this.setState({
                              loading: false,
                              isPaidModal: false,
                              showAddCostModal: false
                         }, () => {
                              this.fetchCaseDetails(filterObj);
                         });
                    },
                    { ...filterObj },
                    "POST"
               );
          });
     }

     openAddCostModal = (costUpdation) => {
          let modalHeading = '';
          let modalSubHeading = '';
          let isPaidModal = false;
          if (costUpdation) {
               modalHeading = 'Please enter the cost';
               modalSubHeading = '';
               isPaidModal = false;
          } else {
               modalHeading = 'Are you sure ? ';
               modalSubHeading = 'Do you wish to mark these checks as paid';
               isPaidModal = true;
          }
          this.setState({
               showAddCostModal: true,
               modalHeading,
               isPaidModal,
               modalSubHeading
          })
     }

     hideAddCostModal = () => {
          this.setState({
               showAddCostModal: false
          })
     }

     handleCostChange = (key, value) => {
          if (isNaN(value)) {
          } else {
               this.setState({
                    [key]: value
               });
          }
     }

     togglePaid = (key, value) => {
          let { filterObj } = this.state;
          filterObj = {
               ...filterObj,
               [key]: value
          }
          this.setState({
               filterObj
          }, () => {
               this.fetchCaseDetails(filterObj);
          })
     }

     hideSnackbar = () => {
          this.setState({showSnackMessage: false});
     }

     render = () => {
          const animatedComponents = makeAnimated();
          this.init();
          let { checkCode, checkDetailsList, filterObj, showTable, totalCount, selectedCaseIdList, cost, isPaidModal, modalSubHeading, modalHeading, showAddCostModal, showSnackMessage, snackMessage, snackStatus } = this.state;
          const disableFilter = this.checkId ? false : true;
          return (
               <React.Fragment>
                    {
                         showSnackMessage ? <BTSnackbar msg={snackMessage} status={snackStatus} time="2000" onHide={this.hideSnackbar} /> : ''
                    }
               
                    {this.state.loading ? <LoadingSpinner /> : <React.Fragment>
                         < div className="clr20" ></div >
                         <div className="row">
                              <div className="col-sm-12 col-md-3">
                                   <div className="form-group court_multi" style={{ zIndex: "1000" }}>
                                        <div className="body2 mb10">Select a Check</div>
                                        <Select
                                             id="checkId"
                                             placeholder="Select check"
                                             style={{ border: "2px solid red", zIndex: "1000" }}
                                             options={this.checkList}
                                             defaultValue={this.defObjCheck}
                                             onChange={this.onCheckChange}
                                        ></Select>
                                   </div>
                              </div>
                              <div className="col-sm-12 col-md-3">
                                   <div className="form-group court_multi" style={{ zIndex: "1000" }}>
                                        <div className="body2 mb10">Choose a Vendor</div>
                                        <Select
                                             id="statusList"
                                             components={animatedComponents}
                                             placeholder="Choose Vendors"
                                             style={{ border: "2px solid red", zIndex: "1000" }}
                                             options={this.vendorOptions}
                                             onChange={(e) => this.onMultiSelectChange(e, 'selectedVendorOptionList', 'vendorIdList')}
                                             value={filterObj.selectedVendorOptionList}
                                             isMulti
                                             className="basic-multi-select"
                                             classNamePrefix="select"
                                        ></Select>
                                   </div>
                              </div>
                              <div className="col-sm-12 col-md-3">
                                   <div className="form-group court_multi" style={{ zIndex: "1000" }}>
                                        <div className="body2 mb10">Choose a Client</div>
                                        <Select
                                             id="clientList"
                                             components={animatedComponents}
                                             placeholder="Search clients"
                                             style={{ border: "2px solid red", zIndex: "1000" }}
                                             options={this.clientList}
                                             onChange={(e) => this.onMultiSelectChange(e, 'selectedClientOptionList', 'clientIdList')}
                                             value={filterObj.selectedClientOptionList}
                                             isMulti
                                             className="basic-multi-select"
                                             classNamePrefix="select"
                                        ></Select>
                                   </div>
                              </div>
                              <div className="col-3">
                                   <div className="body2 mb10">{'Search'}</div>
                                   <div className="form-group search-filter left search_box_width">
                                        <input type="text" className="form-control" id="searchString" placeholder={'Name / Case Number'} name='searchString' value={filterObj.searchString} onChange={(e) => this.handleValueChange(e.target.name, e.target.value)} />
                                        <span className="bt-icon bt-search"></span>
                                   </div>
                              </div>
                              <div className='clr5'></div>
                              <div className="col-12 col-sm-3 col-md-3 ">
                                   <BTDatePicker label={("From Date")} customClass={true} customClassName='form_control_bg'
                                        name='startDate' value={filterObj.startDate} handleDateChange={this.handleValueChange} showTimeSelect={false} required={true} />
                              </div>
                              <div className="col-12 col-sm-3 col-md-3 ">
                                   <BTDatePicker label={("To Date")} customClass={true} customClassName='form_control_bg'
                                        name='toDate' value={filterObj.toDate} handleDateChange={this.handleValueChange} showTimeSelect={false} required={true} />
                              </div>
                              {checkCode === 'residence' ? <div className="col-12 col-sm-3 col-md-3">
                                   <BTSelectField id="stage" name='stage' label={"Select a Stage"} selectClassName='form_control_bg' required={true} value={filterObj.stage} options={this.stageOptionTags} onChange={(e) => this.handleValueChange(e.target.name, e.target.value)} />
                              </div> : null}
                              <div className="col-sm-12 col-md-3" style={{ marginTop: "30px" }}>
                                   <div className="row">
                                        <div className={!disableFilter?"col-sm-10":"col-sm-12"}>
                                             <span
                                                  className={`btn btn_primary left  ${disableFilter ? 'disabled_btn' : ''}`}
                                                  onClick={() => this.fetchCaseDetails(filterObj)}
                                                  style={{ width: '100%' }}
                                             >Filter</span>
                                        </div>
                                        {!disableFilter ? <div className="col-sm-2">
                                             <span className='title-hover downloadxls left mt0'>
                                                  <img className='downloadxls ' height="30" src={"/images/icons/icon-download.svg"} alt="download" onClick={this.downloadVendorXLS} ></img>
                                                  <div className="icon-details pad10">
                                                       <div className="body2 mt5">Download as XLS</div>
                                                  </div>
                                             </span>
                                        </div> : null}
                                   </div>
                              </div>
                         </div>
                         <div className="clr10"></div>
                         {!showTable ? null :
                              <>
                                   <VendorCostingTableRow checkDetailsList={checkDetailsList} filterObj={filterObj} handleSelectAll={this.handleSelectAll} selectedCaseIdList={selectedCaseIdList} handleSelectChange={this.handleSelectChange} totalCount={totalCount} checkCode={checkCode} openAddCostModal={this.openAddCostModal} togglePaid={this.togglePaid}

                                   /></>}
                         {
                              this.state.showError ?
                                   <div className="showerrormsg">
                                        <span>Please fill all the details</span>  </div> : ""
                         }
                         <div className="modal-dialog ">
                              <ConfirmationModal
                                   show={showAddCostModal}
                                   heading={modalHeading}
                                   successButtonText="Save"
                                   failureButtonText="Cancel"
                                   onHide={this.hideAddCostModal}
                                   className="reset-popup"
                                   showFooter="false"
                                   hideFooter={true}
                                   subheading={modalSubHeading}
                                   closeCallback={this.hideAddCostModal} >
                                   <>
                                        {isPaidModal ? <>
                                             <div className="col-12 text-center">
                                                  <div className='clr20'></div>
                                                  <span className={`btn btn_primary  ml10 `} onClick={this.costUpdation}>Yes</span>
                                             </div>
                                        </> : <div className="row">
                                                  <div className="col-sm-12">
                                                       <div className="form-group">
                                                            <div className="body2 mb10">{'Cost'}</div>
                                                            <div className="form-group search-filter left search_box_width">
                                                                 <input type="text" className="form-control" id="cost" name='cost' value={cost} onChange={(e) => this.handleCostChange(e.target.name, e.target.value)} />
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div className="col-12 text-center">
                                                       <span className={`btn btn_primary mt10  ml10 ${(cost.length >= 1 && cost > 0) ? '' : 'btn_disabled'}`} onClick={this.costUpdation}>Save</span>
                                                  </div>
                                             </div>
                                        }
                                   </>
                              </ConfirmationModal>
                         </div>
                    </React.Fragment >}
               </React.Fragment>
          );
     }
}

export default withTranslation('translations')(VendorCostingLayout);
