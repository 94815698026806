/* eslint-disable react/jsx-props-no-spreading */
import { encryptionKey, restbaseurl } from '../constants'
import Cookies from 'js-cookie';
import { Route, Navigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import * as ls from "local-storage";
import UserInfo from '../../models/UserInfo';
import { RestDataSource } from '../../utils/RestDataSource';
import { jwtVerify, SignJWT } from 'jose';
import { LoadingSpinner } from 'utils/LoadingSpinner';

function parseJwt(token) {
     const base64Url = token.split('.')[1];
     const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
     const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
     console.log('Payload', jsonPayload);
     return JSON.parse(jsonPayload);
}

function updateC1() {
     const expmin = new Date(new Date().getTime() + 20 * 60 * 1000);
     const val1 = Cookies.get('_c1');
     if (val1 !== undefined && val1 !== null) {
          console.log('updateC1  val1', val1);
          Cookies.remove('_c1');
          Cookies.set('_c1', val1, { path: '/', expires: expmin });
          const p = parseJwt(val1);
          console.log('update - C1 Payload', p);
     }
}

function updateUserInfo(c1 = null) {
     let val1 = c1;
     const ck = Cookies.get('_c1');
     if (ck != null) {
          val1 = ck;
          console.log('Thru cookie');
     } else if (c1 !== undefined && c1 !== null) {
          ls.set('_c1', val1);
          console.log('Thru Params - post login');
     }
     if (val1 === undefined || val1 === null) {
          val1 = ls.get('_c1');
          console.log('Thru Local Store');
     }
     console.log('Final Val1', val1);

     if (val1 !== undefined && val1 !== null) {
          const data = parseJwt(val1);
          UserInfo.setUserDetail(data);
     } else {
          processLogout();
          console.log('updateUserInfo - Cookie / LS / Params not found');
     }
}

async function getValue(key = "") {
     console.log("getValue:", key);
     const token = ls.get('token')
     if (token !== null && token !== undefined && token!="") {
          try{
               // let { payload } = await jwtVerify(token, encryptionKey,
               //      {
               //           algorithms: ['HS256'], // Ensure the algorithm matches the one used for signing
               //      }
               // ) // Need to verify
               let payload = JSON.parse(atob(token));
               console.log("getValue:", key,payload);
               return key === "" ? payload  : payload?payload[key]:null
          }catch(error){
               if (error.name === 'JWTExpired') {
                    // Logic to refresh the token or prompt the user to log in again
                    console.log('Token expired, refreshing...');
                    ls.clear();
                    document.location.href = '/';
                }
          }
     }
     return null
}

async function setValue(key, value) {
     let token = ls.get('token')
     console.log("setValue:", key, value);

     if (token !== null && token !== undefined) {
          // const { payload } = await jwtVerify(token, encryptionKey,
          //      {
          //           algorithms: ['HS256'],
          //      }
          // )
          const payload = await getValue();

          payload[key] = value;
          console.log("payload:", payload);
          setAllValue(payload);
     }
}

async function setAllValue(payload) {
     if (payload !== null && payload !== undefined) {
          console.log("payload:", payload);
          // const token = await new SignJWT({ ...payload })
          //      .setProtectedHeader({ alg: 'HS256' }) // Set the protected header with the algorithm
          //      .setIssuedAt()
          //      .setExpirationTime('12h')
          //      .sign(encryptionKey)
          const token = btoa(JSON.stringify(payload))
          ls.set('token', token);
     }
}

function isUserLoggedIn (){
     return new Promise((resolve) => {
          getValue().then(async (pLoadData) => {
               if (pLoadData && pLoadData['isloggedin']) {
                    resolve({"flag":true});
               } else {
                    ls.clear();
				document.location.href = '/home';
               }
          });
     })
}

function PrivateRoute({ component: Component, cprops, ...rest }) {
     console.log("PrivateRoute:")
     const [payloadData, setPayloadData] = useState(null);
     const [isValid, setIsValid] = useState(false);
     const [isAccessable, setIsAccessable] = useState(null);
     const location = useLocation();
     useEffect(() => {
          let valid = false;
          let accessable = false;
          getValue().then((pData) => {
               console.log('loggedvlue:', pData);
               pData = pData?pData:{};
               valid = (pData['isloggedin'] !== undefined
                    && pData['isloggedin'] !== null
                    && pData['isloggedin'] === true) ? true : false;
               const path = rest.match;
               // 0: {id: 1, name: "Admin", code: "admin", priority: 1}
               // 6: {id: 7, name: "Operations Head", code: "operationshead", priority: 2}
               // 3: {id: 4, name: "Happiness Partner", code: "chp", priority: 3}
               // 1: {id: 2, name: "Client Admin", code: "ca", priority: 4}
               // 2: {id: 3, name: "HR", code: "hr", priority: 5}
               // 5: {id: 6, name: "Operations", code: "operations", priority: 6}
               // 7: {id: 8, name: "Data Entry", code: "de", priority: 7}
               // 4: {id: 5, name: "Accounts", code: "accounts", priority: 8}
               // 8: {id: 9, name: "Report Viewer", code: "reportviewer", priority: 9}
               // 9: {id: 9, name: "Document Viewer", code: "documentviewer", priority: 9}
     
     
               const role = pData['roleCode'];
               // let accessableUrls = {
               //      admin: ['/chooseRole', '/onboarding', '/dashboard', '/adminDashboard', '/operations'],
               //      operationshead: ['/onboarding', '/dashboard', '/adminDashboard'],
               //      chp: ['/onboarding', '/dashboard', '/adminDashboard'],
               //      ca: ['/onboarding', '/dashboard'],
               //      hr: ['/onboarding', '/dashboard'],
               //      operations: ['/operations'],
               //      vendor: ['/vendorDashboard'],
               //      candidate: ['/candidate'],
               // }
               // if (role) {
               //      if( (role==='chp' || role==='operationshead' ) &&  getValue('additionalRole')){
               //           accessableUrls[role].push('/chooseRole')
               //           accessableUrls[role].push('/operations')
               //      }
               // }
               if (role) {
                    console.log('loggedRole---', role)
                    console.log('loggedpath---', path)
                    switch (path) {
     
                         case '/chooseRole':
                              // if (((getValue('rolePriority') === getValue('roleMap')['admin'] || (getValue('rolePriority') === getValue('roleMap')['de'])) ||
                              //      ((role === 'chp' || role === 'operationshead') && getValue('additionalRole') && getValue('additionalRole').length))) {
                              if ((pData['rolePriorityList'].length >= 2)) {
                                   accessable = true;
                              }
                              break
                         case '/operations':
                              // if ((role === 'operations') ||
                              // ((getValue('rolePriority') <= getValue('roleMap')['chp']) && getValue('additionalRole') && getValue('additionalRole').length)) {
                              accessable = true;
                              // }
                              break;
                         case '/adminDashboard':
                              if ((pData['rolePriority'] <= pData['roleMap']['chp'])) {
                                   accessable = true;
                              }
                              break;
                         case '/dashboard':
                         case '/onboarding':
                              if ((pData['rolePriority'] <= pData['roleMap']['hr'])) {
                                   accessable = true;
                              }
                              break;
                         case '/vendorDashboard':
                              if (role === 'vendor') {
                                   accessable = true;
                              }
                              break;
                         case '/candidate':
                              if (role === 'candidate') {
                                   accessable = true;
                              }
                              break;
                         case '/reportViewerDashboard':
                              if (role === 'reportviewer') {
                                   accessable = true;
                              }
                              break;
                         case '/documentViewerDashboard':
                              if (role === 'documentviewer') {
                                   accessable = true;
                              }
                              break;
                         default:
                              document.location.href = '/';
                    }
               } else {
                    if ((pData['rolePriorityList'] && pData['rolePriorityList'].length >= 2)) {
                         accessable = true;
                    }
               }
               setIsValid(valid);
               setIsAccessable(accessable);
               setPayloadData(pData);
          })
     },[])
     
    // waiting for payload data from localstore
     if (!payloadData) {
          return (<></>);
     }

     // console.log("valid && isAccessable:",isValid +"&&"+ isAccessable,payloadData)
     if (payloadData && !(isValid && isAccessable)) {
          // return <Navigate to={'/home'} state={{ from: location }} />;
     }

     return <Component {...cprops} location={location} {...rest}/>

}


function processLogout() {
     ls.clear();
}

function importGoogleMapsJS() {
     console.log("Google maps")
     let keyRequest = new RestDataSource(`${restbaseurl}/login/obtainGoogleMapsApiKey`)

     keyRequest.GetData(
          response => {
               if (response.status === true && response.key.length > 0) {
                    const script = document.createElement("script");
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${response.key}&libraries=places`;
                    script.async = true;
                    script.defer = true;
                    document.body.appendChild(script);
                    console.log("loaded Google Maps JS");
               }
               else {
                    console.error("Error loading Google Maps JS", response);
               }
          },
          {},
          "POST"
     );

}

export {
     parseJwt, updateC1, updateUserInfo, processLogout, getValue, setValue, setAllValue, isUserLoggedIn, PrivateRoute, importGoogleMapsJS
};
