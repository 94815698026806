import { useJsApiLoader } from '@react-google-maps/api'

import { useState } from 'react';
import { RestDataSource } from './RestDataSource';
import { restbaseurl } from 'globals/constants';

export default function FetchGoogleAPI(props) {

  const [googleApiKey, setGoogleApiKey] = useState(null);

  function fetchGoogleAPIKey() {
    let keyRequest = new RestDataSource(`${restbaseurl}/login/obtainGoogleMapsApiKey`)
    keyRequest.GetData(
      response => {
        if (response.status === true && response.key.length > 0) {
          setGoogleApiKey(response.key);
          console.log("Google API key", response.key)

        }
        else {
          console.error("Error loading Google Maps JS", response);
        }
      },
      {},
      "POST"
    );
  }

  fetchGoogleAPIKey();

  return (
    <>
      {googleApiKey && <FetchUseJsApiLoader googleApiKey={googleApiKey} />}
    </>

  )
}




const FetchUseJsApiLoader = (props) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: props.googleApiKey,
    libraries: ['places','maps','marker','geocoding'],
  });
  return (<div />);
}
