import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Select from "react-select";
import { restbaseurl } from '../globals/constants';
import { RestDataSource } from '../utils/RestDataSource';
import ConfirmationModal from '../globals/uicomps/ConfirmationModal';
import UserInfo from '../models/UserInfo';
import { getValue } from '../globals/utils';
import { BTSelectField } from '../globals/uicomps/BTSelectField';

import DialogModal from '../globals/uicomps/DialogModal';
import AddressDetailsModal from './AddressDetailsModal';

class VerificationStatusDetails extends Component {
     constructor(props) {
          super(props);
          this.state = {
               loading: false,
               vendorName: '',
               dummy: false,
               vendorId: this.props.vendorDetails.currentId,
               unAssign: false,
               subheading: '',
               showConfirmationModal: false,
               vendorOptions: this.props.vendorOptions,
               address: '',
               openAddressDetailsModal: false,
               payloadData: null
          };
          // this.vendorOptions = []
          this.totalVendorList = [];

          getValue().then((pLData) => {
               this.setState({ payloadData: pLData });
          });
     }

     handleResidenceChange = () => {
          this.saveResidence = new RestDataSource(
               `${restbaseurl}/residence/reAssignVendor`
          );
          this.setState({ loading: true }, () => {
               this.saveResidence.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   showConfirmationModal: false,
                                   cbRender: this.props.cbRender()
                              })
                         }
                    },
                    {
                         "vendorId": this.state.vendorId,
                         "id": this.props.vendorDetails.resId
                    },
                    "POST"
               );
          });
     }

     handleDrugChange = (isReAssign) => {
          let action = '';
          if (isReAssign) {
               action = 'reAssignVendor';
          }
          else {
               action = 'assignVendor';
          }
          this.saveDrug = new RestDataSource(
               `${restbaseurl}/drug/${action}`
          );
          this.setState({ loading: true }, () => {
               this.saveDrug.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   showConfirmationModal: false,
                                   cbRender: this.props.cbRender()
                              })
                         }
                    },
                    {
                         "vendorId": this.state.vendorId,
                         "id": this.props.vendorDetails.drugId,
                         userId: UserInfo.getUserid(),
                    },
                    "POST"
               );
          });
     }



     handleCreditChange = (isReAssign) => {
          let action = '';
          if (isReAssign) {
               action = 'reAssignVendor';
          }
          else {
               action = 'assignVendor';
          }
          this.saveCibil = new RestDataSource(
               `${restbaseurl}/cibil/${action}`
          );
          this.setState({ loading: true }, () => {
               this.saveCibil.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   showConfirmationModal: false,
                                   cbRender: this.props.cbRender()
                              })
                         }
                    },
                    {
                         "vendorId": this.state.vendorId,
                         "id": this.props.vendorDetails.creditId,
                         userId: UserInfo.getUserid(),
                    },
                    "POST"
               );
          });
     }


     confirmSuccessCallback = () => {
          switch (this.currentCheckType) {
               case 'residence':
                    if (this.state.unAssign) {
                         this.unAssignVendor()
                    } else {
                         this.handleResidenceChange()
                    }
                    break;
               case 'cibil':
                    if (this.state.unAssign) {
                         this.unAssignVendor()
                    } else {
                         if (this.props.vendorDetails.currentId && this.props.vendorDetails.currentId !== '' && this.props.vendorDetails.currentId !== null) {
                              this.handleCreditChange(true)
                         } else {
                              this.handleCreditChange(false)
                         }
                    }
                    break;
               case 'drug':
                    if (this.state.unAssign) {
                         this.unAssignVendor()
                    } else {
                         if (this.props.vendorDetails.currentId && this.props.vendorDetails.currentId !== '' && this.props.vendorDetails.currentId !== null) {
                              this.handleDrugChange(true)
                         } else {
                              this.handleDrugChange(false)
                         }

                    }
                    break;
               default:
                    break
          }

     }

     unAssignVendor = () => {
          const currentCheckType = this.currentCheckType;
          this.unAssign = new RestDataSource(
               `${restbaseurl}/${currentCheckType}/unassignVendor`
          );
          let id = '';
          if (currentCheckType === 'residence') {
               id = this.props.vendorDetails.resId;
          } else if (currentCheckType === 'cibil') {
               id = this.props.vendorDetails.creditId;
          } else if (currentCheckType === 'drug') {
               id = this.props.vendorDetails.drugId;
          }

          this.setState({ loading: true }, () => {
               this.unAssign.GetData(
                    data => {
                         if (data.status === true) {
                              // this.vendorOptions = this.totalVendorList;
                              this.setState({
                                   showConfirmationModal: false,
                                   unAssign: false,
                                   cbRender: this.props.cbRender()
                              })
                         }
                    },
                    {
                         "id": id,
                         userId: UserInfo.getUserid(),
                    },
                    "POST"
               );
          });
     }

     onVendorChange = (event) => {
          this.setState({
               vendorName: event.label,
               vendorId: event.value,
               showConfirmationModal: true,
               unAssign: false,
               subheading: `Do you want to assign  ${event.label} to this check `
          })
     }

     unAssignVendorConfirmation = () => {
          this.setState({
               showConfirmationModal: true,
               unAssign: true,
               subheading: `Do you want to UnAssign vendor to this case `
          })
     }

     generateAddressListOptions = () => {
          let addressList = this.props.addressList
          this.addressOptionTags = addressList.map(row => {
               return <option key={row.id} value={row.id + '-' + row.code + '-' + row.drugId}>{row.address}</option>
          });
          this.addressOptionTags = [<option id={0} key={0} value="">Select one</option>, ...this.addressOptionTags]
          return this.addressOptionTags;
     }

     handleChange = () => {
          let selectedValue = document.getElementById("address").value
          this.setState({
               address: selectedValue
          })
     }

     saveAddress = () => {
          this.source = new RestDataSource(`${restbaseurl}/drug/tagAddress`);
          this.setState({ loading: true }, () => {
               this.source.GetData(
                    data => {
                         if (data.status === true) {
                              this.props.cbRender()
                         }
                    },
                    { addressStr: this.state.address },
                    "POST"
               );
          });
     }

     addNewAddress = () => {
          this.preventRender = true
          this.setState({
               openAddressDetailsModal: true
          })
     }

     hideModal = () => {
          this.setState({ showdialog: false, openAddressDetailsModal: false });
     };

     render = () => {
          const { t } = this.props
          let value = this.props.obj[this.props.data]
          let showReassignVendor = false, addAddress = false
          
          const { openAddressDetailsModal } = this.state;
          if (this.state.payloadData) {
               let roleCode = this.state.payloadData["roleCode"];
               if (this.props.data === "Process Status" && value !== "Completed" && this.props.vendorDetails.reassign === true
                    && this.state.payloadData['rolePriority'] !== this.state.payloadData['roleMap']['ca'] && this.state.payloadData['rolePriority'] !== this.state.payloadData['roleMap']['hr']) {
                    showReassignVendor = true
               }
               if (this.props.verificationType === "Drug Check" && this.props.data === "Address List" && (roleCode === "chp" || roleCode === "admin")) {
                    addAddress = true
               }
               let showRemoveVendor = false
               if ((this.props.vendorDetails.currentId !== null && this.props.data === "Vendor Name" && this.state.payloadData['rolePriority'] <= this.state.payloadData['roleMap']['operationshead'] && this.props.obj['Process Status'] !== 'Completed')) {
                    showRemoveVendor = true
               }
               this.vendorOptions = this.totalVendorList.filter(data => data.value !== this.props.vendorDetails.currentId);
               if (this.props.verificationType === "Residence Check") {
                    this.currentCheckType = 'residence'
               } else if (this.props.verificationType === "Credit Check") {
                    this.currentCheckType = 'cibil'
               } else if (this.props.verificationType === "Drug Check") {
                    this.currentCheckType = 'drug'
               }

               if (this.props.data === "Address List") {
                    value = value.map(addressObj => {
                         return (
                              <React.Fragment>
                                   <div>{addressObj.address}</div>
                              </React.Fragment>
                         )
                    })
               }
          }

          return (
               <React.Fragment>
                    {addAddress ?
                         <React.Fragment>
                              <div className="col-12 col-sm-6 mb15 ml5 mr5">
                                   <React.Fragment>
                                        <BTSelectField
                                             id="address"
                                             label="Add Address"
                                             onChange={this.handleChange}
                                             value={this.state.address}
                                             options={this.generateAddressListOptions()}
                                             required={true} />
                                        <div style={{ marginTop: '-15px' }}>
                                             <div className="btn btn_secondary_light btn_secondary right" onClick={this.addNewAddress}>{t("add_Address")} </div>
                                             <div className="btn btn_secondary_light btn_secondary right mr-2" onClick={this.saveAddress}>{t("save")} </div>
                                        </div>
                                   </React.Fragment>
                              </div>
                         </React.Fragment>
                         :
                         <div className={this.props.data === 'Document String' ? "col-12 col-sm-12 mb15" : "col-12 col-sm-6 mb15"}>
                              <div className="smallfont">{
                                   this.props.data ? (this.props.data) : ' - '}
                                   {(showRemoveVendor) ? <img alt="icon-close" src={require('../images/icons/icon-close.svg')} className="remove_vendor pointer" onClick={this.unAssignVendorConfirmation} /> : null}
                              </div>
                              <div className="clr5"></div>
                              <div className="smallfont body2">{value ? value : ' - '}</div>
                              <div className="clr10"></div>
                         </div>
                    }
                    {showReassignVendor ?
                         <React.Fragment>
                              <div className="col-12 col-sm-6 mb15">
                                   <div className="form-group ml5 mr5">
                                        {this.state.vendorOptions.length ?
                                             <React.Fragment>
                                                  <label className="sub-heading1">{"Choose a vendor to Assign/Reassign"}</label>
                                                  <Select
                                                       id="vendorId"
                                                       style={{ border: "2px solid red" }}
                                                       options={this.state.vendorOptions}
                                                       value=''
                                                       onChange={this.onVendorChange}
                                                  ></Select>
                                             </React.Fragment> : "No vendors available to reassign "}
                                   </div>
                              </div>
                         </React.Fragment>
                         : null
                    }
                    <ConfirmationModal
                         show={this.state.showConfirmationModal}
                         heading="Are you sure ?"
                         subheading={this.state.subheading}
                         successButtonText="Yes"
                         failureButtonText="No"
                         successCallback={() => { this.confirmSuccessCallback() }}
                         failureCallback={() => { this.setState({ showConfirmationModal: false }) }}
                         closeCallback={() => { this.setState({ showConfirmationModal: false }) }}
                    ></ConfirmationModal>
                    {openAddressDetailsModal ? <div className="modal-dialog ">
                         <DialogModal
                              show={this.state.openAddressDetailsModal}
                              header="Enter Address Details"
                              successButtonText="Submit"
                              failureButtonText="Cancel"
                              onHide={this.hideModal}
                              className="cibil-popup emp_modal"
                              showFooter="false"
                         >
                              <AddressDetailsModal
                                   id={this.props.caseId}
                                   successCallback={this.props.cbRender}
                                   hideModal={this.hideModal}
                              />
                         </DialogModal>
                    </div> : null}
               </React.Fragment>
          );
     }

}
export default withTranslation('translations')(VerificationStatusDetails);


