import React from 'react';
import { createRoot } from 'react-dom/client';
// import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import * as error from 'react-error-boundary';
import i18n from "./i18n";

import './css/bootstrap.min.css';
import './css/metapath.css';
import './css/custom.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { InternalError } from './globals/InternalError';
// var console = {};
// console.log = function(){};
// window.console = console;
// eslint-disable-next-line no-unused-vars
const myErrorHandler = (error, componentStack) => {
     // Do something with the error
     // E.g. log to an error logging client here
};

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<error.ErrorBoundary FallbackComponent={InternalError}>
     {/* <GoogleReCaptchaProvider reCaptchaKey="6LdoU70UAAAAACR-SdzkokQJcKJQUMasMAb1y5Cg"> */}
     <I18nextProvider i18n={i18n}>
          <Router><App /></Router>
     </I18nextProvider>
     {/* </GoogleReCaptchaProvider> */}
</error.ErrorBoundary>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
