import React, { Component } from 'react'
import CaseVerification from './CaseVerification'
import { withTranslation } from "react-i18next";
import CaseReport from '../../../caseReport/CaseReport';
import Axios from 'axios';
import moment from 'moment';
import LoadingModal from '../../../globals/uicomps/LoadingModal';
import BTSnackbar from '../../../globals/uicomps/BTSnackbar'
import { getValue } from '../../../globals/utils';
import QcDetails from '../../../qualityCheck/QcDetails';
import QcOperationDetails from '../../../qualityCheck/QcOperationDetails';
import { restbaseurl } from '../../../globals/constants';

class Tablerow extends Component {
     constructor(props) {
          super(props);
          this.state = {
               caseRowloading: false,
               caseReportId: this.props.id,
               showCaseReport: false,
               showBottomAlert: false,
               snackbar: false,
               snackmsg: '',
               snackStatus: '',
               reportDownloadProgress: null,
               roleCode:'',
               isAdminOrChp:false

          }
          getValue().then((payloadData)=>{
               this.setState({
                    roleCode:payloadData['roleCode'],
                    isAdminOrChp:payloadData['isAdminOrChp']
               })
          })
     }

     getStatusName = (status, completed) => {
          if (completed) {
               return 'Completed'
          }
          if (status === 'suspicious') {
               return 'Amber'
          }
          else if (status === 'clear') {
               return 'Completed'
          }
          else if (status === 'failed') {
               return 'In Progress'
          }
          else if (status === 'in-progress') {
               return "In progress"
          }
          else if (status === 'yet-to-start') {
               return "Yet to start"
          }
          else if (status === 'insufficiency') {
               return "Insufficiency"
          }
          else if (status === 'cepending') {
               return "CE - Pending"
          }
          else if (status === 'in-progress-match-found') {
               return "In Progress - Match Found"
          }
          else {
               return status
          }
     }

     onShowCaseReport = (e) => {
          e.preventDefault();
          this.setState({
               caseReportId: this.props.caseItem.id,
               showCaseReport: true
          });
     }
     onHideCaseReport = () => {
          this.setState({
               caseReportId: null,
               showCaseReport: false
          });
     }

     hideSnackbar = () => {
          this.setState({ snackbar: false, snackmsg: '', snackStatus: '' });
     }

     onStopCaseHide = () => {
          this.setState({
               caseReportId: null,
               showCaseReport: false,
               snackbar: true,
               snackmsg: "Case Stopped Successfully",
               snackStatus: "success",
          });
     }

     hideModal = () => {
          this.setState({
               caseRowloading: false
          })
     }
     updateDownloadProgress = (e) => {
          if(e.lengthComputable === true){
               const loaded = e.loaded;
               const total = e.total;
               let progress = loaded/total;
               if(progress>1){
                    progress = 1;
               }
               this.setState({reportDownloadProgress: progress});
          }
     }

     fetchReport = (apiURL) => {
          this.setState({
               caseRowloading: true
          })
          Axios(`${apiURL}`, {
               method: 'GET',
               responseType: 'blob', //Force to receive data in a Blob Format
               onDownloadProgress: this.updateDownloadProgress
          })
               .then(response => {
                    this.setState({
                         caseRowloading: false,
                         reportDownloadProgress: null,
                    })
                    const file = new Blob(
                         [response.data],
                         { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
               })
               .catch(error => {
                    this.setState({
                         caseRowloading: false,
                         reportDownloadProgress: null,
                         snackbar: true,
                         snackStatus: "danger",
                         snackmsg: "Error downloading report",
                    })
               });
     }

     fetchFIR = (id) => {
          this.setState({
               caseRowloading: true
          })
          const formData = new FormData();
          let url = `${restbaseurl}/applicantCase/downloadCheckWiseSampleReport`;
          formData.append('caseId', id);
          formData.append('selectedVerificationList', 'id,court,database');
          Axios.post(url, formData, {
               responseType: 'blob', withCredentials: true
               //Force to receive data in a Blob Format
          })
               .then(response => {
                    this.setState({
                         caseRowloading: false
                    })
                    const file = new Blob(
                         [response.data],
                         { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
               })
               .catch(error => {
                    console.log(error);
               });
     }

     copyCaseNumber = (event, value) => {

          // debugger;
          event.target.nextElementSibling.firstElementChild.innerText = 'Copied'
          // console.log(event.target, 'eeevent')
          // for (var i = 0; i < x.length; i++) {
          //      x[i].innerText = "Copied";    // Change the content
          // }
          var body = document.getElementsByTagName('body')[0];
          var tempInput = document.createElement('INPUT');
          body.appendChild(tempInput);
          tempInput.setAttribute('value', value)
          tempInput.select();
          tempInput.setSelectionRange(0, 99999)
          document.execCommand('copy');
          body.removeChild(tempInput);
          event.stopPropagation();
     }

     changeHoverContent = (event) => {
          event.target.nextElementSibling.firstElementChild.innerText = 'Copy to clipboard'
     }

     showChildTable = (e, activeRow) => {
          this.props.showChildTable(activeRow);
          e.stopPropagation();
     }
     
     render() {
          const { t, qcView , qcOperationsView} = this.props;
          let isMailerTemplateAvailable = this.props.caseItem.isMailerTemplateAvailable;
          let emailForMailerTemplate = this.props.caseItem.emailForMailerTemplate;
          let showReport = true
          let isReportShared = this.props.caseItem.isReportShared;
          if (!(this.state.isAdminOrChp)) {
               if (isMailerTemplateAvailable) {
                    if (!isReportShared) {
                         showReport = false
                    }
               }
          }
          let verificationList = this.props.caseItem.verificationTypeList ? this.props.caseItem.verificationTypeList : [];
          let statusVerify = verificationList.map((verify, index) => {
               return <CaseVerification key={index} data={verify.verificationTypeName} statusCode={verify.verificationStatusCode} verificationList={verify.verificationList} verificationStatus={verify.verificationStatus} />
          })

          let reportName = this.props.caseItem.reportType;
          let displayName = reportName;
          if (displayName === 'Final Report') {
               displayName = 'FR'
          } else if (displayName === 'Interim Report') {
               displayName = 'ITR'
          }

          return (
               <React.Fragment>
                    {this.state.snackbar ?
                         <BTSnackbar msg={this.state.snackmsg} status={this.state.snackStatus} time="4000" onHide={this.hideSnackbar} /> : ""}

                    <tr className="case-row" onClick={this.onShowCaseReport}   >
                         
                         <td className="grid-list">
                              {this.props.caseItem.isTopPriority?<div class="ribbon ribbon-top-left"><span>priority</span></div> : null}
                              {this.props.idx + 1} {qcView || qcOperationsView ? 
                                   <img
                                        src="/images/icons/icon-down-arrow.svg" width="20" alt="user" className="arrow_down pointer"
                                        onClick={(e) => this.showChildTable(e, this.props.idx + 1)} />
                              : ''}
                              {this.props.priorityCase ? <img src="/images/icons/danger.svg" width="16" alt="user" className="ml-2" /> : ''}
                         </td>
                         <td className='showHoverDetails' style={{ width: '140px' }}>
                              {this.props.caseItem.comments !== null && this.props.caseItem.comments !== '' ?
                                   <div className="case-icons">
                                        {this.props.caseItem.caseNumber}
                                        <span className="hover_header">
                                             <img src={"/images/icons/icon-copy.svg"} height="15" alt="clone pack" onMouseLeave={this.changeHoverContent} onClick={(e) => this.copyCaseNumber(e, this.props.caseItem.caseNumber)} className="pointer ml5 mb5" />
                                             <div className="hover_data">
                                                  <div className="sub-heading2 copyTextContect">Copy to clipboard</div>
                                             </div>
                                        </span>
                                        <div className="title-hover ml_minus">
                                             <span className="pointer mr10"><img src="/images/icons/icon-info.svg" height="12" alt="info-icon" /></span>
                                             <div className="icon-details"><div className="sub-heading1">{this.props.caseItem.caseNumber}</div>
                                                  <div className="body2 mt5">{this.props.caseItem.comments}</div>
                                             </div>
                                             <div className="clr"></div>
                                        </div></div> :
                                   <React.Fragment>
                                        {this.props.caseItem.caseNumber}
                                        <span className="hover_header">
                                             <img src={"/images/icons/icon-copy.svg"} height="15" alt="clone pack" onMouseLeave={this.changeHoverContent} onClick={(e) => this.copyCaseNumber(e, this.props.caseItem.caseNumber)} className="pointer ml5 mb5 " />
                                             <div className="hover_data">
                                                  <div className="sub-heading2 copyTextContect">Copy to clipboard</div>
                                             </div>
                                        </span>
                                   </React.Fragment>
                              }
                         </td>
                         {this.props.dataEntryView
                              ?
                              <td>{this.props.caseItem.clientName}</td>
                              : ''
                         }
                         <td className="widthname">{this.props.caseItem.name}</td>
                         <td>{this.props.caseItem.initiatedOn}</td>
                         <td>{this.props.caseItem.caseStatusCode === 'insufficiency' ? 'On Hold' : moment(this.props.caseItem.tatDate).format('DD MMM')}</td>
                         <td>{this.props.caseItem.tatStatus}</td>
                         <td>
                              <div className="case-icons">
                                   {statusVerify}
                              </div>
                         </td>

                         <td className='verificationstatus'>
                              <div className={` ${this.props.caseItem.caseStatusReportColor}`} >{this.props.caseItem.caseStatusName}</div>
                         </td>

                         {this.props.dataEntryView
                              ?
                              ''
                              :
                              <div className="position-relative">
                                   <td>
                                        {
                                             reportName === "Pending" ?
                                                  <span className="heading2 link_disabled" title='Report Not Ready'>{t('NR')}</span>
                                                  :
                                                  this.state.caseRowloading ?
                                                       <div className="modal-dialog ">
                                                            <LoadingModal
                                                                 show={this.state.caseRowloading}
                                                                 successButtonText="Submit"
                                                                 failureButtonText="Cancel"
                                                                 content="Report is being generated please wait"
                                                                 onHide={this.hideModal}
                                                                 time={10000}
                                                                 backdropType="static"
                                                                 showFooter={true}
                                                                 progress={this.state.reportDownloadProgress}
                                                            />
                                                       </div>
                                                       : showReport ? <span className={`sub-heading2 ${isReportShared ? 'clear' : ''}  `} title={`Click to download ${reportName}`} target="_blank" rel="noopener noreferrer" onClick={(e) => { this.fetchReport(this.props.caseItem.reportUrl, this.props.caseItem.name); e.stopPropagation() }}>{displayName}</span> :
                                                            <span className={`sub-heading2 ${isReportShared ? 'clear' : ''} disable_report_download `} title={`Click to download ${reportName}`} onClick={(e) => e.stopPropagation()}>{displayName}</span>
                                        }
                                   </td>
                              </div>
                         }
                    </tr>
                    {(qcView || qcOperationsView) && this.props.idx + 1 === this.props.activeRow? 
                    <tr className='show case-row'>
                         <td colSpan="10" style={{padding: "0px"}}>
                              {
                                   qcOperationsView ? 
                                        <QcOperationDetails caseId={this.props.caseItem.id} />
                                   :
                                        <QcDetails caseId={this.props.caseItem.id} showSecondLevelQc={this.props.showSecondLevelQc}/>
                              }
                         </td>
                    </tr>
                    :''
                    }
                    {this.state.showCaseReport ? <CaseReport caseId={this.props.caseItem.id} reportUrl={this.props.caseItem.reportUrl} reportName={reportName} onHide={this.onHideCaseReport} onStopCaseHide={this.onStopCaseHide} fetchReport={this.fetchReport} showReport={showReport} isMailerTemplateAvailable={isMailerTemplateAvailable} caseObj={this.props.caseItem} emailForMailerTemplate={emailForMailerTemplate} fetchFIR={this.fetchFIR} /> : null}
               </React.Fragment>
          )
     }
}
export default withTranslation("translations")(Tablerow);

