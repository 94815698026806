import React, { Component } from 'react';

import { LoginHeader } from './LoginHeader';
import LoginForm from './LoginForm';
// import { LoginFooter } from './LoginFooter';
import { VerifyOTP } from './VerifyOTP';
import { restbaseurl } from '../../globals/constants';
import { RestDataSource } from '../../utils/RestDataSource';
import { LoadingSpinner } from '../../utils/LoadingSpinner';
import ForgotPassword from './ForgotPassword';
import BottomAlert from '../../globals/uicomps/BottomAlert'
import ResetPassword from './ResetPassword';
import PhotoAuthenticator from "./PhotoAuthenticator"
import AccountSuspend from './AccountSuspend';
import TryAnotherWays from "./TryAnotherWays"
import Authenticator from './Authenticator';
import { Route, Routes, Navigate } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import md5 from 'md5';
import { getValue, setAllValue, setValue } from '../../globals/utils';
import UserInfo from '../../models/UserInfo';
import { encryptionKey } from '../../globals/constants'
import * as ls from "local-storage";
import { SignJWT } from 'jose';
import { WithRouterHooks } from 'withFunctionHooks';
class LoginLayout extends Component {

     constructor(props) {
          super(props);
          let index = this.props.location.pathname.lastIndexOf("/");
          let step = this.props.location.pathname.substr(index + 1).trim()
          console.log(index, step);
          const aTab = this.props.location.pathname.split("/")[2];
          this.state = {
               alertMsg: null,
               userData: null,
               forgotPassword: false,
               otpVerified: false,
               isSuspended: false,
               anotherWay: false,
               isAuthMFAEnabled: null,
               isAuthPhotoEnabled: null,
               step: aTab!=undefined?aTab: "login",
               fullLoading: false,
          };
          this.userName = '';
          getValue().then((payloadData)=>{
               this.setState({isAuthMFAEnabled: payloadData['authenticatorEnabled'],
                    isAuthPhotoEnabled: payloadData['faceIdEnabled']})
          })
     }

     componentDidMount = async () => {
          if (await getValue('isloggedin')) {
               const role = await getValue('roleCode')
               if (role === 'vendor') {
                    this.props.navigate('/vendorDashboard');
               } else {
                    this.props.navigate('/');
               }
               // document.location.href = "/vendorDashboard";
          }
          document.body.classList.add('login-bg');
     }

     validateLogin = (username, password) => {
          this.dataSource = new RestDataSource(`${restbaseurl}/login/vendorAuth`)
          this.setState({ loading: true });
          this.dataSource.GetData(
               async respData => {
                    if (respData.message === 'success') {
                         console.log("login success");
                         await setValue('isloggedin', false);
                         await setValue('isForgotPassword', false);
                         await setValue('username', respData.data.username);
                         await setValue('vendorId', respData.data.vendorId);
                         await setValue('userId', respData.data.vendorId);
                         await setValue('clientId', respData.data.clientId);
                         await setValue('loginname', respData.data.firstname);
                         await setValue('clientEmail', respData.data.clientEmail);
                         await setValue('remainingTries', respData.data.remainingTries);
                         await setValue('otpExpiryTime', respData.data.otpExpiryTime);
                         await setValue('mobilenumber', respData.data.mobilenumber);
                         await setValue('authenticatorEnabled', respData.data.authenticatorEnabled);
                         await setValue('faceIdEnabled', true);
                         await setValue('roleCode', respData.data.role);
                         await setValue('accessibleTabs', respData.data.accessibleTabs);
                         await setValue('rolePriority', '');
                         await setValue('rolePath', '/vendorDashboard')
                         await getValue('authenticatorEnabled') ?
                              this.setState({ loading: false, userdata: respData.data, step: 'tryAnotherWays', alertMsg: null },()=>{
                                   this.props.navigate(this.state.step);
                              })
                              : this.setState({ loading: false, userdata: respData.data, step: 'verifyOtp', alertMsg: null }, () => {
                                   this.changeStepValue('verifyOtp')
                              })

                    }
                    else if (respData.isBlocked === true) {
                         this.setState({ loading: false, step: 'blocked' },()=>{
                              this.props.navigate(this.state.step);
                         })
                    }
                    else {
                         this.setState({ loading: false, alertMsg: respData.message });
                    }
               },
               { "username": username, "password": md5(password) },
               "POST"
          );
     }

     validateDetails = (userName, mobileNumber) => {
          this.checkDetails = new RestDataSource(`${restbaseurl}/login/forgotVendorPassword`)
          this.setState({ loading: true });
          this.checkDetails.GetData(
              async (respData) => {
                    if (respData.message === 'success') {
                         const token = await setAllValue({
                              'isloggedin': false,
                              'isForgotPassword': true,
                              'username': respData.data.username,
                              'userId': respData.data.userId,
                              'vendorId': respData.data.vendorId,
                              'firstTimeLogin': respData.data.firstTimeLogin,
                              'loginname': respData.data.firstname,
                              'clientEmail': respData.data.clientEmail,
                              'otpExpiryTime': respData.data.otpExpiryTime,
                              'mobilenumber': respData.data.mobilenumber,
                              'authenticatorEnabled': respData.data.authenticatorEnabled,
                              'faceIdEnabled': false,
                              'rolePriority': '',
                              'rolePath': '',
                              'roleCode': respData.data.role,
                         });

                         // setValue('token', respData.data.token);
                         // setValue('isForgotPassword', true);
                         // setValue('isloggedin', false);
                         // setValue('username', respData.data.username);
                         // setValue('vendorId', respData.data.vendorId);
                         // setValue('clientId', respData.data.clientId);
                         // setValue('firstTimeLogin', respData.data.firstTimeLogin);
                         // setValue('loginname', respData.data.firstname);
                         // setValue('clientEmail', respData.data.clientEmail);
                         // setValue('otpExpiryTime', respData.data.otpExpiryTime);
                         // setValue('mobilenumber', respData.data.mobilenumber);
                         // setValue('authenticatorEnabled', respData.data.authenticatorEnabled);
                         // setValue('faceIdEnabled', false);
                         // setValue('roleCode', respData.data.role);
                         // setValue('accessibleTabs', respData.data.accessibleTabs);

                         // this.setState({ loading: false, step: 'verifyOtp', isAuthPhotoEnabled: false });
                         // this.setState({ loading: false, isAuthPhotoEnabled: false, userdata: respData.data, step: 'verifyOtp', alertMsg: null }, () => {
                         //      this.changeStepValue('verifyOtp')
                         // })
                         this.setState({
                              loading: false, step: 'verifyOtp', isAuthPhotoEnabled: false,
                         },()=>{
                              this.props.navigate(this.state.step);
                         });
                    }
                    else {
                         this.setState({ loading: false });
                         this.showAlert(respData.message)
                    }
               },
               { "userName": userName, "mobileNumber": mobileNumber },
               "POST"
          );
     }

     resendOTP = () => {
          this.checkResend = new RestDataSource(`${restbaseurl}/login/resendVendorOtp`)
          this.showAlert(null);
          this.setState({ loading: true }, async () => {
               this.checkResend.GetData(
                    async resendResponse => {
                         this.setState({ loading: false })
                         if (resendResponse.isBlocked) {
                              this.changeStepValue("blocked")
                         }
                         else {
                              await setValue('remainingTries', resendResponse.remainingTries)
                         }
                    },
                    { 'vendorId': await getValue('vendorId'), "username": UserInfo.getUsername() },
                    "POST"
               );
          })
     }

     triggerOtp = async () => {
          this.otpRequest = new RestDataSource(`${restbaseurl}/login/triggerVendorOtp`);
          this.setState({ loading: true });
          this.otpRequest.GetData(
               async respData => {

                    if (respData.status === 'success') {
                         if (respData.isBlocked) {
                              this.changeStepValue("blocked");
                         } else {
                              await setValue('remainingTries', respData.remainingTries);
                         }
                    } else {
                         this.showAlert(respData.message)
                    }
                    this.setState({ loading: false });
               },
               { "vendorId": await getValue('vendorId') },
               "POST"
          );
     }

     verifyOTP = (otp) => {
          this.verifyOTPReq = new RestDataSource(`${restbaseurl}/login/vendorOtpLogin`)
          this.showAlert(null);
          this.setState({ loading: true }, async () => {
               this.verifyOTPReq.GetData(
                    async respData => {
                         this.setState({ loading: false })
                         if (respData.message === 'success') {
                              if (await getValue('isForgotPassword')) {
                                   this.changeStepValue("resetPassword")
                              } else {
                                   this.loginSuccess();
                              }
                         }
                         else {
                              if (respData.isBlocked === true) {
                                   this.changeStepValue("blocked")
                              } else {
                                   this.showAlert(respData.message)
                              }
                         }
                    },
                    {
                         'vendorId': await getValue('vendorId'),
                         "username": UserInfo.getUsername()?UserInfo.getUsername():await getValue('username'),
                         "otp": otp
                    },
                    "POST"
               );
          })
     }

     loginSuccess = async () => {
          this.setState({ fullLoading: true });
          await setValue('isloggedin', true);
          document.body.classList.remove('login-bg');
          document.location.href = "/vendorDashboard";
     }

     changeStepValue = (stepValue, userName) => {
          if (stepValue === 'verifyOtp') {
               this.triggerOtp();
          }
          this.userName = userName;
          this.setState({ step: stepValue },()=>{
               this.props.navigate(this.state.step);
          });
     }

     showAlert = (alertMsg) => {
          this.setState({ alertMsg: alertMsg });
     }

     render = () => {
          let path = this.props.match.path !== '/' ? this.props.match.path : '/home';
          return (

               <React.Fragment>
                         <GoogleReCaptchaProvider reCaptchaKey="6Lf3aZ8dAAAAAPQhvzKA5CkCpfBvPIGoSiS-1eUJ">
                              {this.state.fullLoading ? <LoadingSpinner /> :
                                   <React.Fragment>
                                        <LoginHeader />
                                        <div className="login-block">
                                             <div className="login-set">
                                                  {
                                                       this.state.loading === true ? <LoadingSpinner /> :
                                                            <React.Fragment>
                                                                 {
                                                                      (this.state.alertMsg) ? <BottomAlert status="danger" msg={this.state.alertMsg} /> : null
                                                                 }
                                                                 {
                                                                      <Routes>
                                                                           <Route path={`verifyOtp`} element={<VerifyOTP match={`${path}/verifyOtp`} {...this.state.userdata} showAlert={this.showAlert} loginSuccess={this.loginSuccess} changeStepValue={this.changeStepValue} resendOTP={this.resendOTP} verifyOTP={this.verifyOTP} />} />
                                                                           <Route path={`tryAnotherWays`} element={<TryAnotherWays  match={`${path}/tryAnotherWays`} {...this.state.userdata} showAlert={this.showAlert} changeStepValue={this.changeStepValue} />} />
                                                                           <Route path={`photoAuthenticator`} element={<PhotoAuthenticator match={`${path}/photoAuthenticator`} {...this.state.userdata} showAlert={this.showAlert} changeStepValue={this.changeStepValue} />} />
                                                                           <Route path={`authenticator`} element={<Authenticator match={`${path}/authenticator`} {...this.state.userdata} showAlert={this.showAlert} changeStepValue={this.changeStepValue} loginSuccess={this.loginSuccess} />} />
                                                                           <Route path={`forgotPassword`} element={<ForgotPassword match={`${path}/forgotPassword`} userName={this.userName} forwardToOtp={this.validateDetails} changeStepValue={this.changeStepValue} />} />
                                                                           <Route path={`resetPassword`} element={<ResetPassword match={`${path}/resetPassword`} showAlert={this.showAlert} loginSuccess={this.loginSuccess} changeStepValue={this.changeStepValue} />} />
                                                                           <Route path={`blocked`} element={<AccountSuspend match={`${path}/blocked`} changeStepValue={this.changeStepValue} />} />
                                                                           <Route path={`login`} element={<LoginForm match={`${path}/login`}  cbValidateLogin={this.validateLogin} changeStepValue={this.changeStepValue} />} />
                                                                           <Route path="/" element={<Navigate to={this.state.step} />} />
                                                                      </Routes>
                                                                 }
                                                            </React.Fragment>
                                                  }
                                             </div>
                                             <div className="clr"></div>
                                        </div>
                                   </React.Fragment>
                                   /* <LoginFooter /> */
                              }
                              <img className="loginbg" src={'/images/login-bg.svg'} alt='' />
                         </GoogleReCaptchaProvider>
               </React.Fragment>
          );
     }
}
export default WithRouterHooks(LoginLayout);
//
/*



     */