import React, { Component } from 'react';
/*global google*/
import { restbaseurl } from "../globals/constants";
import { RestDataSource } from "../utils/RestDataSource";
import { withTranslation } from 'react-i18next';
//  
import queryString from 'query-string';
import BTGoogleMap from './BTGoogleMap';
import { Navbar } from "react-bootstrap";
import OpenViduLayout from './openVidu/OpenViduLayout';
import Axios from 'axios';
import { LoadingSpinner } from "../utils/LoadingSpinner";
// import Compressor from 'compressorjs';
import { SingleDocsUpload } from '../globals/uicomps/SingleDocsUpload';
import { BTInputField } from '../globals/uicomps/BTInputField';
import { BTSelectField } from '../globals/uicomps/BTSelectField';
class VerificationBaseLayout extends Component {

     constructor(props) {
          super(props);
          this.state = {
               loading: false,
               showMap: true,
               showGoogleMap: false,
               isEditable: true,
               markedLocation: '',
               showExteriorUpload: true,
               showLandmarkUpload: true,
               showIdentityUpload: true,
               exteriorFile: '',
               landmarkFile: '',
               identityFile: '',
               isMapEditable: true,
               isVideoCallEnabled: false,
               showFinalSession: false,
               userData: {},
               addressDetails: {},
               linkedExpired: false,
               compressedFileList: [],
               isVoiceCallEnabled: false,
               preview: false,
               verifierDetails: {
                    respondentName: '',
                    relationship: '',
                    respondentPhoneNumber: '',
                    periodOfStayFrom: '',
                    periodOfStayTo: '',
                    ownership: ''
               }
          };
          this.queryDataDetails = '';
          this.handleSaveExteriorImage = this.handleSaveExteriorImage.bind(this);
          this.handleSaveLandmarkImage = this.handleSaveLandmarkImage.bind(this);
          this.handleSaveIdentityImage = this.handleSaveIdentityImage.bind(this);
          this.compressedFileList = []
     }

     componentDidMount = () => {
          this.init();
          this.fetchData()
     }

     getGeoLocation = (lat, lng) => {
          let latlng;
          // eslint-disable-next-line new-parens
          let geocoder = new window.google.maps.Geocoder();
          latlng = new window.google.maps.LatLng(lat, lng);
          geocoder.geocode({ 'latLng': latlng }, (results, status) => {
               if (status === 'OK') {
                    if (results[0]) {
                         let processedAddressData = this.processPlaceObject(results[0]);
                         this.setState({
                              addressDetails: processedAddressData
                         })
                         this.saveAutoGeoCapture()
                    } else {
                         // this.fetchData()
                    }
               } else {
                    // this.fetchData()
               }
          });
     }

     init = () => {
          this.queryDataDetails = queryString.parse(this.props.location.search);
     }

     processPlaceObject = (place) => {
          let addressLine = ''
          let locality = '';
          let pincode = '';
          let city = '';
          let state = '';
          let country = '';
          // eslint-disable-next-line no-unused-vars
          for (let adidx in place.address_components) {
               let addressComponent = place.address_components[adidx];
               let addressLineTypes = ['street_number', 'route', 'sublocality_level_1', 'sublocality', 'locality'];
               // eslint-disable-next-line no-unused-vars
               for (let alti in addressLineTypes) {
                    let addrType = addressLineTypes[alti];
                    if (addressComponent.types.includes(addrType)) {
                         addressLine = addressLine ? addressLine + ", " + addressComponent.long_name : addressComponent.long_name;
                         break;
                    }
               }
               if (addressComponent.types.includes('sublocality')) {
                    locality = addressComponent.long_name;
               }
               if (addressComponent.types.includes('administrative_area_level_2')) {
                    city = addressComponent.long_name;
               }
               if (addressComponent.types.includes('administrative_area_level_1')) {
                    state = addressComponent.long_name;
               }
               if (addressComponent.types.includes('country')) {
                    country = addressComponent.long_name;
               }
               if (addressComponent.types.includes('postal_code')) {
                    pincode = addressComponent.long_name;
               }
          }

          let latitude = place.geometry.location.lat();
          let longitude = place.geometry.location.lng();
          let placeId = place.id;

          console.log('place-data', addressLine, locality, city, pincode, state, country, latitude, longitude, placeId);

          let addressDetails = {
               addressLineOne: addressLine,
               locality: locality,
               city: city,
               state: state,
               country: country,
               latitude: latitude,
               longitude: longitude,
               placeId: placeId,
               pincode: pincode
          };

          return addressDetails;
     }

     getAutoGeoLocation = () => {
          let lat, lng;
          navigator.geolocation.getCurrentPosition((position) => {
               lat = position.coords.latitude
               lng = position.coords.longitude;
               this.getAutoGeoLocation(lat, lng)
          })
     }

     saveAutoGeoCapture = () => {
          let data = this.queryDataDetails
          this.saveGeoAuto = new RestDataSource(`${restbaseurl}/residence/saveCandidateGeoLocation`)
          this.saveGeoAuto.GetData(
               data => {
                    if (data.status === true) {
                         this.setState({
                              loading: false
                         })
                    }
               },
               { "residenceVerificationId": data.id, mode: "auto", geoLocation: this.state.addressDetails },
               "POST"
          );
     }

     fetchData = () => {
          let data = this.queryDataDetails
          this.checkValidity = new RestDataSource(`${restbaseurl}/residence/checkUrlValidity`)
          this.setState({ loading: true }, () => {
               this.checkValidity.GetData(
                    data => {
                         if (data.status === true) {
                              if (data.validityStatus) {
                                   this.setState({
                                        loading: false,
                                        userData: data.data,
                                   })
                              } else {
                                   this.setState({
                                        loading: false,
                                        linkedExpired: true
                                   })
                              }
                         }
                    },
                    { "residenceVerificationId": data.id, token: data.hashToken, time: data.currentTime },
                    "POST"
               );
          });
     }

     onMapSubmit = (localityData, canvas, markedLocation) => {
          let data = this.queryDataDetails
          this.saveGeoManual = new RestDataSource(`${restbaseurl}/residence/saveCandidateGeoLocation`)
          this.setState({ loading: true }, () => {
               this.saveGeoManual.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   markedLocation: markedLocation,
                                   isMapEditable: false,
                                   loading: false
                              })
                              navigator.geolocation.getCurrentPosition((position) => {
                                   this.getGeoLocation(position.coords.latitude, position.coords.longitude);
                              });
                         }
                    },
                    { "residenceVerificationId": data.id, mode: "manual", geoLocation: localityData },
                    "POST"
               );
          });
     }

     onMapClose = () => {
          this.setState({
               showMap: false
          })
     }

     showGoogleMap = () => {
          this.setState({
               showGoogleMap: true
          })
     }

     handleSaveExteriorImage = (attachmentFile) => {
          this.setState({ exteriorFile: attachmentFile })
     }

     removeExteriorImage = () => {
          this.setState({ exteriorFile: '' })
     }

     handleSaveLandmarkImage = (attachmentFile) => {
          this.setState({ landmarkFile: attachmentFile })
     }

     removeLandmarkImage = () => {
          this.setState({ landmarkFile: '' })
     }

     handleSaveIdentityImage = (attachmentFile) => {
          this.setState({ identityFile: attachmentFile })
     }

     removeIdentityImage = () => {
          this.setState({ identityFile: '' })
     }

     saveDetails = () => {
          let { exteriorFile, landmarkFile, identityFile, verifierDetails } = this.state;
          let fileList = [exteriorFile, landmarkFile, identityFile]
          let data = this.state.userData
          let id = this.queryDataDetails.id
          let url = `${restbaseurl}/residence/candidateVerificationSubmit`;
          let formData = new FormData();
          formData.append('id', id);
          formData.append('verifiedByCandidate', true);
          formData.append('respondentName', verifierDetails.respondentName);
          formData.append('relationship', verifierDetails.relationship);
          formData.append('respondentPhoneNumber', verifierDetails.respondentPhoneNumber);
          formData.append('periodOfStayFrom', verifierDetails.periodOfStayFrom);
          formData.append('periodOfStayTo', verifierDetails.periodOfStayTo);
          formData.append('ownership', verifierDetails.ownership);

          if (data.evidenceList.length > 0) {
               data.evidenceList.forEach((file, index) => {
                    formData.append(`existingEvidence`, file.docPath)
               });
               if (data.evidenceList.length === 1) {
                    formData.append(`isNotList`, true)
               }
          }
          fileList.forEach((file, index) => {
               formData.append(`file`, file.file)
          });

          const config = {
               headers: { "content-type": "multipart/form-data" },
               withCredentials: true
          };
          this.setState({ loading: true });
          Axios.post(url, formData, config)
               .then((resp) => {
                    const data = resp.data;
                    if(data.status === true){
                         this.setState({
                              loading: false,
                              isEditable: false,
                              isMapEditable: false,
                              showFinalSession: true,
                         });
                    }else{
                         this.setState({
                              loading: false,
                              isEditable: false,
                              isMapEditable: false,
                         });
                    }
               })
               .catch(error => {
                    console.error(error);
               });
     }

     editDetails = () => {
          this.setState({
               isEditable: true,
               isMapEditable: true
          })
     }
     configureVideoCall = () => {
          window.alert('Before proceeding to video call , make sure your browser app has permission to access your microphone and camera ')
          this.setState({
               isVideoCallEnabled: true,
               isVoiceCallEnabled: false
          })
     }

     endCommunicationSession = () => {
          this.setState({
               isVideoCallEnabled: false,
               isVoiceCallEnabled: false,
               showFinalSession: true
          })
     }

     configureVoiceCall = () => {
          window.alert('Before proceeding to voice call , make sure your browser app has permission to access your microphone')
          this.setState({
               isVoiceCallEnabled: true,
               isVideoCallEnabled: false
          })
     }

     showPreview = () => {
          this.setState({ preview: true }, () => {
               document.documentElement.scrollTop = 0;
          })
     }

     hidePreview = () => {
          this.setState({ preview: false });
     }

     handleChange = (e) => {
          const { verifierDetails } = this.state;
          const key = e.target.id;
          const value = e.target.value;
          this.setState({ verifierDetails: { ...verifierDetails, [key]: value } });
     }

     generateOwnershipOptions = () => {
          let ownershipList = ["Owned", "Rental", "PG", "Hostel", "Quarters"]
          this.ownershipOptionTags = ownershipList.map(row => {
               return <option key={row} value={row.toLowerCase()}>{row}</option>
          });
          this.ownershipOptionTags = [<option id={0} key={0} value={''}>Select one</option>, ...this.ownershipOptionTags]
     }

     render = () => {
          const { t } = this.props;
          let userData = this.state.userData
          let stateData = this.state
          let submitDisabled = '';
          let id = this.queryDataDetails.id;
          let {
               exteriorFile,
               landmarkFile,
               identityFile,
               isEditable,
               preview,
               verifierDetails,
          } = this.state;
          this.generateOwnershipOptions();

          // TODO: add marked location check => stateData.markedLocation === '' ||
          if (
               stateData.markedLocation === '' ||
               exteriorFile === '' ||
               landmarkFile === '' ||
               identityFile === '' ||
               verifierDetails.respondentName === '' ||
               verifierDetails.relationship === '' ||
               verifierDetails.respondentPhoneNumber === '' ||
               verifierDetails.periodOfStayFrom === '' ||
               // verifierDetails.periodOfStayTo === '' ||
               verifierDetails.ownership === ''
          ) {
               submitDisabled = 'disabled'
          }
          return (

               <React.Fragment>
                    <Navbar className="box-shadow-card" fixed="top">
                         <Navbar.Brand href="/"><img src={'/images/logo.svg'} alt="metapath" className="companylogoclass"></img></Navbar.Brand>
                    </Navbar>
                    {this.state.loading ? <LoadingSpinner /> :
                         <React.Fragment>
                              {this.state.linkedExpired ? <React.Fragment>
                                   <div className="verification_container">
                                        <div className="verification_wrapper white-bg text-center" style={{ height: '50px' }}>
                                             <div className="sub-heading1 mr15">Link has been Expired</div>
                                        </div></div>
                              </React.Fragment> :
                                   <div className="verification_container ">
                                        <div className="verification_wrapper">
                                             <div className="white-bg">
                                                  <div className="card-head">
                                                       <div className="sub-heading1 left mr15">{t("instructions")}</div>
                                                       <div className="clr10"></div>
                                                       <hr />
                                                       <div className="clr"></div>
                                                       <div className="row">
                                                            <div className="col-sm-6">
                                                                 <ol className="ol-disc">
                                                                      <li className="bold red-text">The Link will be valid for only 20 minutes after you click on the link.</li>
                                                                      <li>Enable the GPS location.</li>
                                                                      <li>The Photo files should be JPEG, JPG only(live photo).</li>
                                                                      <li>
                                                                           The photos to be taken are
                                                                           <ol>
                                                                                <li>House Exterior Photo.</li>
                                                                                <li>Landmark photo(Temple, Petrol Pump, School, Bank, Grocery or Medical Shop, etc...)</li>
                                                                                <li>ID Proof( PAN CARD, AADHAR, PASSPORT or DRIVEING LICENCE).</li>
                                                                           </ol>
                                                                      </li>
                                                                 </ol>
                                                            </div>
                                                       </div>
                                                       <div className="clr"></div>
                                                  </div>
                                             </div>
                                             <div className="clr20"></div>
                                             <div className="white-bg">
                                                  <div className="card-head">
                                                       <div className="sub-heading1 left mr15">{userData.name}</div>
                                                       <div className="clr10"></div>
                                                       <hr />
                                                       <div className="clr"></div>
                                                       <div className="row">
                                                            <div className="col-sm-6">
                                                                 <div className="location">
                                                                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                                                           viewBox="0 0 246 408.75" x="0px" y="0px" clipRule="evenodd"
                                                                           fillRule="evenodd">
                                                                           <g>
                                                                                <path
                                                                                     d="M123 0c68,0 123,55 123,123 -1,68 -77,160 -123,204 -46,-41 -122,-139 -123,-204 0,-68 55,-123 123,-123zm0 295c42,-44 99,-120 100,-172 0,-55 -45,-100 -100,-100 -55,0 -100,45 -100,100 1,50 58,129 100,172z" />
                                                                                <path
                                                                                     d="M123 71c29,0 52,23 52,52 0,29 -23,52 -52,52 -29,0 -52,-23 -52,-52 0,-29 23,-52 52,-52zm0 23c16,0 29,13 29,29 0,16 -13,29 -29,29 -16,0 -29,-13 -29,-29 0,-16 13,-29 29,-29z" />
                                                                           </g>
                                                                      </svg>
                                                                      <p>
                                                                           {userData.address}
                                                                      </p>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div className="clr"></div>
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="clr20"></div>
                                        {stateData.isVideoCallEnabled ?
                                             <React.Fragment>
                                                  <div className="App openvidu_wrap">
                                                       <OpenViduLayout myUserName={stateData.userData.name} sessionId={stateData.userData.sessionId} endCommunicationSession={this.endCommunicationSession} type='videoCall'></OpenViduLayout>
                                                  </div>
                                             </React.Fragment > : null}
                                        {stateData.isVoiceCallEnabled ?
                                             <React.Fragment>
                                                  <div className="App openvidu_wrap">
                                                       <OpenViduLayout myUserName={stateData.userData.name} sessionId={stateData.userData.sessionId} endCommunicationSession={this.endCommunicationSession} type='voiceCall' ></OpenViduLayout>
                                                  </div>
                                             </React.Fragment > : null}
                                        {stateData.showFinalSession ?
                                             <React.Fragment>
                                                  <div className='text-center'>
                                                       <div className='clr10'></div>
                                                       <img src={"/images/tick.svg"} alt="tick" ></img>
                                                       <div className='clr15'></div>
                                                       <h6>Done</h6>
                                                       <div className='sub-heading1'>Thanks for you cooperation</div>
                                                       <div className='clr10'></div>
                                                       <div className='sub-heading4'>Reach out to contact@meta-path.ai for any assistance</div>
                                                  </div>
                                             </React.Fragment>
                                             : null}
                                        {(stateData.isVideoCallEnabled || stateData.showFinalSession || stateData.isVoiceCallEnabled) ? null :
                                             <React.Fragment>
                                                  <div className="white-bg location_pin_wrap">
                                                       {(!stateData.showGoogleMap && stateData.isEditable) ?
                                                            <div className="upload-div" onClick={this.showGoogleMap}>
                                                                 <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                                                      viewBox="0 0 246 408.75" x="0px" y="0px" clipRule="evenodd"
                                                                      fillRule="evenodd" className="pin_location">
                                                                      <g>
                                                                           <path
                                                                                d="M123 0c68,0 123,55 123,123 -1,68 -77,160 -123,204 -46,-41 -122,-139 -123,-204 0,-68 55,-123 123,-123zm0 295c42,-44 99,-120 100,-172 0,-55 -45,-100 -100,-100 -55,0 -100,45 -100,100 1,50 58,129 100,172z" />
                                                                           <path
                                                                                d="M123 71c29,0 52,23 52,52 0,29 -23,52 -52,52 -29,0 -52,-23 -52,-52 0,-29 23,-52 52,-52zm0 23c16,0 29,13 29,29 0,16 -13,29 -29,29 -16,0 -29,-13 -29,-29 0,-16 13,-29 29,-29z" />
                                                                      </g>
                                                                 </svg>
                                                                 <div>  Pin my location</div>
                                                                 {/* <input type="file" name="file" multiple={false}  /></div> */}
                                                            </div>
                                                            : null}
                                                       {(stateData.showGoogleMap && stateData.isMapEditable) ?
                                                            <div>
                                                                 <BTGoogleMap onLocationSubmit={this.onMapSubmit} useCurrentLocation={true} onHide={this.onMapClose} />
                                                            </div> : null}
                                                       {(stateData.showGoogleMap && !stateData.isMapEditable) ?
                                                            <div>
                                                                 <React.Fragment>
                                                                      <span className='sub-heading2'>Address</span>
                                                                      <div className='clr10'></div>
                                                                      {stateData.markedLocation}
                                                                 </React.Fragment>
                                                            </div> : null}
                                                  </div>
                                                  <div className="clr15"></div>
                                                  <div className="white-bg black_color">
                                                       <div className="clr20"></div>
                                                       <div className="row">
                                                            <div className="col-sm-6">
                                                                 <div className="form-group">
                                                                      <BTInputField
                                                                           type="text"
                                                                           name="respondentName"
                                                                           label={"Name of the Respondent"}
                                                                           id="respondentName"
                                                                           required={true}
                                                                           disabled={!isEditable || preview}
                                                                           handleChange={this.handleChange}
                                                                           value={verifierDetails.respondentName}
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                 <div className="form-group">
                                                                      <BTInputField
                                                                           type="text"
                                                                           name="relationship"
                                                                           label={"Relationship with the candidate"}
                                                                           id="relationship"
                                                                           required={true}
                                                                           disabled={!isEditable || preview}
                                                                           handleChange={this.handleChange}
                                                                           value={verifierDetails.relationship}
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                 <div className="form-group">
                                                                      <BTInputField
                                                                           label={"Respondent Contact number"}
                                                                           type="tel"
                                                                           id="respondentPhoneNumber"
                                                                           value={verifierDetails.respondentPhoneNumber}
                                                                           disabled={!isEditable || preview}
                                                                           required={true}
                                                                           handleChange={this.handleChange}
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="clr"></div>
                                                            <div className="col-sm-6">
                                                                 <div className="form-group">
                                                                      <BTInputField
                                                                           type="date"
                                                                           label={"Period of Stay ( From )"}
                                                                           id="periodOfStayFrom"
                                                                           min={this.props.mindate}
                                                                           required={true}
                                                                           disabled={!isEditable || preview}
                                                                           handleChange={this.handleChange}
                                                                           value={verifierDetails.periodOfStayFrom}
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                 <div className="form-group">
                                                                      <BTInputField
                                                                           type="date"
                                                                           label={"Period of Stay ( To )"}
                                                                           id="periodOfStayTo"
                                                                           min={this.props.mindate}
                                                                           disabled={!isEditable || preview}
                                                                           handleChange={this.handleChange}
                                                                           value={verifierDetails.periodOfStayTo}
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <div className="clr"></div>
                                                            <div className="col-sm-6">
                                                                 <div className="form-group">
                                                                      <BTSelectField
                                                                           id="ownership"
                                                                           label="Ownership"
                                                                           isDisabled={!isEditable || preview}
                                                                           onChange={this.handleChange}
                                                                           value={verifierDetails.ownership}
                                                                           options={this.ownershipOptionTags}
                                                                           required={true} />
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div className="clr15"></div>
                                                  <div className="row residence_candidate_verify">
                                                       <SingleDocsUpload
                                                            key='exterior_image'
                                                            allowedDocs=' jpg, jpeg, png'
                                                            maxSizeMB={0.1}
                                                            allowedDocsList={['jpg', 'jpeg', 'png']}
                                                            cbSaveDocument={this.handleSaveExteriorImage}
                                                            cardsPerRow={2}
                                                            includeHeader={false}
                                                            headerText=''
                                                            uploadMessage='Upload picture of the house (exterior)'
                                                            isPreview={!isEditable || preview}
                                                            enableRemoveOption={false}
                                                            subHeader=''
                                                            uploadTypeIcon='/images/icons/icon-image.svg'
                                                            uploadedFile={exteriorFile}
                                                            isFileUploaded={exteriorFile !== '' ? true : false}
                                                            altText='exterior_image'
                                                            removeDocument={this.removeExteriorImage}
                                                       />
                                                       <SingleDocsUpload
                                                            key='landmark_image'
                                                            allowedDocs=' jpg, jpeg, png'
                                                            maxSizeMB={0.1}
                                                            allowedDocsList={['jpg', 'jpeg', 'png']}
                                                            cbSaveDocument={this.handleSaveLandmarkImage}
                                                            cardsPerRow={2}
                                                            includeHeader={false}
                                                            headerText=''
                                                            uploadMessage='Upload picture of a landmark nearby'
                                                            isPreview={!isEditable || preview}
                                                            enableRemoveOption={false}
                                                            subHeader='(eg: Street sign , Store , Temple...)'
                                                            uploadTypeIcon='/images/icons/icon-image.svg'
                                                            uploadedFile={landmarkFile}
                                                            isFileUploaded={landmarkFile !== '' ? true : false}
                                                            altText='landmark_image'
                                                            removeDocument={this.removeLandmarkImage}
                                                       />
                                                       <SingleDocsUpload
                                                            key='idProof_'
                                                            allowedDocs=' jpg, jpeg, png'
                                                            maxSizeMB={0.1}
                                                            allowedDocsList={['jpg', 'jpeg', 'png']}
                                                            cbSaveDocument={this.handleSaveIdentityImage}
                                                            cardsPerRow={2}
                                                            includeHeader={false}
                                                            headerText=''
                                                            uploadMessage='Upload picture of your identity proof'
                                                            isPreview={!isEditable || preview}
                                                            enableRemoveOption={false}
                                                            subHeader='(eg: License , Aadhar , PAN...)'
                                                            uploadTypeIcon='/images/icons/icon-image.svg'
                                                            uploadedFile={identityFile}
                                                            isFileUploaded={identityFile !== '' ? true : false}
                                                            altText='landmark_image'
                                                            removeDocument={this.removeIdentityImage}
                                                            setFileName={`idProof_${id}`}
                                                       />
                                                  </div>
                                                  <div className="clr20 mt10"></div>
                                                  <div className='submit_btn '>
                                                       {preview ?
                                                            <React.Fragment>
                                                                 <span className={`btn btn_primary`} onClick={this.hidePreview} >Back</span>
                                                                 <span className={`btn btn_primary ${submitDisabled}`} onClick={this.saveDetails} >Submit</span>
                                                            </React.Fragment> :
                                                            <React.Fragment>
                                                                 {stateData.isEditable ?
                                                                      <span className={`btn btn_primary ${submitDisabled}`} onClick={this.showPreview}>Preview</span> :
                                                                      <React.Fragment>
                                                                           <span className="btn btn_secondary " onClick={this.editDetails} >Edit</span>
                                                                           <span className="btn btn_primary " onClick={this.configureVoiceCall} >Voice Call</span>
                                                                           <span className="btn btn_primary " onClick={this.configureVideoCall} >Video Call</span>
                                                                      </React.Fragment>
                                                                 }
                                                            </React.Fragment>
                                                       }
                                                  </div>
                                             </React.Fragment>}
                                   </div>}</React.Fragment>}
               </React.Fragment>
          );
     }
}
export default withTranslation('translations')(VerificationBaseLayout);